import Toast, { ToastTypes } from 'components/ToastNotify';
import { CancelOrder } from 'firebaseApis/orders';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import {
	SetPosView,
	SetGratuityPercentage,
	SetTotalAmountWithTaxAndGratuity,
	ClearPosData,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';

function Gratuity() {
	const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
	const {
		view,
		totalAmountWithTaxWithoutGratuity,
		gratuityPercentage,
		orderId,
		currency,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const dispatch: any = useDispatch();
	const gratuityPrices = [
		{
			label: '0',
			percentage: 0,
			text: 'No Gratuity',
		},
		{
			label: '15%',
			percentage: 15,
			text: 'Good Service',
		},
		{
			label: '18%',
			percentage: 18,
			text: 'Better Service',
		},
		{ label: '20%', percentage: 20, text: 'Best Service' },
		{
			label: '25%',
			percentage: 25,
			text: 'Outstanding',
		},
	];

	useEffect(() => {
		dispatch(SetGratuityPercentage(0));
		dispatch(SetTotalAmountWithTaxAndGratuity(totalAmountWithTaxWithoutGratuity));
	}, []);

	const cancelOrder = async () => {
		try {
			dispatch(showLoading());
			const response: any = await CancelOrder(orderId.toString());
			dispatch(hideLoading());
			if (response.success) {
				dispatch(ClearPosData());
				Toast({ title: response.message, type: ToastTypes.SUCCESS });
			} else {
				Toast({ title: response.message, type: ToastTypes.ERROR });
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: error?.message, type: ToastTypes.ERROR });
		}
	};

	return (
		<Modal
			size='md'
			title='Add Gratuity'
			closeIcon
			modalId=''
			open={view === 'GRATUITY'}
			setOpen={() => setShowCancelOrderModal(true)}
			content={
				<div className='flex flex-col space-y-5  text-gray-600'>
					<>
						<h1 className='text-2xl'>Suggested Gratuities</h1>
						<div className='grid justify-between gap-5 grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5'>
							{gratuityPrices.map((item, index) => {
								const gratuity =
									(item.percentage / 100) * totalAmountWithTaxWithoutGratuity;
								const totalAmount = gratuity + totalAmountWithTaxWithoutGratuity;

								return (
									<div key={index}>
										<h1 className='text-lg my-1'>{item.text}</h1>
										<div
											className={`w-full cursor-pointer gap-3 flex flex-col bg-white shadow border border-gray-300 p-5 ${
												gratuityPercentage === item.percentage
													? 'bg-info-500 text-white'
													: ''
											}`}
											key={index}
											onClick={() => {
												if (item.percentage !== 0) {
													dispatch(SetGratuityPercentage(Number(item.percentage)));
													dispatch(SetTotalAmountWithTaxAndGratuity(Number(totalAmount)));
												} else {
													dispatch(SetGratuityPercentage(0));
													dispatch(
														SetTotalAmountWithTaxAndGratuity(
															totalAmountWithTaxWithoutGratuity
														)
													);
												}
											}}
										>
											<h1 className='text-3xl font-semibold'>{item.label}</h1>
											<h1>
												Gratuity {currency} {gratuity.toFixed(2)}
											</h1>
											<h1 className='font-semibold'>
												Total {currency} {Number(totalAmount).toFixed(2)}
											</h1>
										</div>
									</div>
								);
							})}
						</div>
					</>

					<div className='flex justify-end'>
						<Button
							title='CONTINUE'
							onClick={() => {
								dispatch(SetPosView('PAYMENT'));
							}}
							variant='contained'
							color='primary'
						/>
					</div>

					{showCancelOrderModal && (
						<Modal
							title='Cancel Order'
							content={
								<>
									<h1>Are you sure you want to cancel this order ?</h1>
									<div className='flex justify-end gap-5'>
										<Button
											title='No'
											onClick={() => setShowCancelOrderModal(false)}
											variant='outlined'
											color='primary'
										/>
										<Button
											title='Yes'
											onClick={() => cancelOrder()}
											variant='contained'
											color='primary'
										/>
									</div>
								</>
							}
							modalId='Cancel Order'
							open={showCancelOrderModal}
							setOpen={setShowCancelOrderModal}
							size='sm'
						/>
					)}
				</div>
			}
		/>
	);
}

export default Gratuity;
