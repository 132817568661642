import {
	SET_SIDE_BAR_STATUS,
	ADD_PRODUCT_TO_LANE,
	REMOVE_ALL_PRODUCTS_FROM_LANE,
	REMOVE_PRODUCT_FROM_LANE,
	SET_POS_VIEW,
	SET_PRODUCT_QUANTITY,
	SET_ORDER_ID,
	SET_CUSTOMER,
	SET_PAYMENT_MODES_WITH_AMOUNT,
	SET_TOTAL_AMOUNT_WITHOUT_TAX_AND_GRATUITY,
	SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY,
	SET_GRATUITY_PERCENTAGE,
	SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY,
	CLEAR_POS_DATA,
	SET_COUPON_INFO,
	SET_CATALOG,
	SET_DEVICE_STATUS,
	SET_TOTAL_AMOUNT_PAID,
} from './PosActionTypes';

export interface IPos {
	view:
		| 'HOME'
		| 'GRATUITY'
		| 'ADD_CUSTOMER'
		| 'PAYMENT'
		| 'PAYMENT_SUCCESSFULL'
		| 'COUPONS_DISCOUNTS';
	laneItems: [];
	deviceStatus: 'CONNECTED' | 'DISCONNECTED';
	totalAmountWithoutTaxAndGratuity: number;
	totalAmountWithTaxWithoutGratuity: number;
	totalAmountWithTaxAndGratuity: number;
	gratuityPercentage: number;
	gratuityAmount: number;
	totalAmountPaid: number;
	orderId: string;
	customer: any;
	couponInfo: {
		couponCode: string;
		discountPercentage: number;
		discountType: 'Percentage' | 'Amount';
		discountAmount: number;
		amountDiscounted: number;
	};
	catalog: any;
	sidebarStatus: string;
	currency: string;
}

const initialState: IPos = {
	deviceStatus: 'DISCONNECTED',
	view: 'HOME',
	laneItems: [],
	totalAmountWithoutTaxAndGratuity: 0,
	totalAmountWithTaxWithoutGratuity: 0,
	gratuityPercentage: 0,
	gratuityAmount: 0,
	totalAmountWithTaxAndGratuity: 0,
	totalAmountPaid: 0,
	couponInfo: {
		couponCode: '',
		discountType: 'Percentage',
		discountPercentage: 0,
		discountAmount: 0,
		amountDiscounted: 0,
	},
	customer: {
		id: 'GUEST',
	},
	orderId: '',
	catalog: null,
	sidebarStatus: 'opened',
	currency: '$',
};

const posReducer = (state: any = initialState, action: any): IPos => {
	switch (action.type) {
		case SET_SIDE_BAR_STATUS: {
			return {
				...state,
				sidebarStatus: action.payload,
			};
		}
		case SET_POS_VIEW:
			return { ...state, view: action.payload };
		case ADD_PRODUCT_TO_LANE:
			return { ...state, laneItems: [...state.laneItems, action.payload] };
		case REMOVE_PRODUCT_FROM_LANE:
			return {
				...state,
				laneItems: state.laneItems.filter(
					(item: any) => item.name !== action.payload
				),
			};
		case SET_PRODUCT_QUANTITY:
			return {
				...state,
				laneItems: state.laneItems.map((item: any) => {
					if (item.name === action.payload.name) {
						return { ...item, quantity: action.payload.quantity };
					}
					return item;
				}),
			};
		case REMOVE_ALL_PRODUCTS_FROM_LANE:
			return { ...state, laneItems: [] };
		case SET_CUSTOMER:
			return { ...state, customer: action.payload };
		case SET_ORDER_ID:
			return { ...state, orderId: action.payload };
		case SET_TOTAL_AMOUNT_WITHOUT_TAX_AND_GRATUITY:
			return {
				...state,
				totalAmountWithoutTaxAndGratuity: action.payload,
			};
		case SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY:
			return {
				...state,
				totalAmountWithTaxWithoutGratuity: action.payload,
			};
		case SET_GRATUITY_PERCENTAGE:
			return {
				...state,
				gratuityPercentage: action.payload,
				gratuityAmount:
					(action.payload * state.totalAmountWithoutTaxAndGratuity) / 100,
			};
		case SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY:
			return {
				...state,
				totalAmountWithTaxAndGratuity: action.payload.toFixed(2),
			};
		case SET_TOTAL_AMOUNT_PAID:
			return { ...state, totalAmountPaid: action.payload };
		case SET_PAYMENT_MODES_WITH_AMOUNT:
			return { ...state, paymentModesWithAmount: action.payload };
		case SET_COUPON_INFO:
			return { ...state, couponInfo: action.payload };
		case SET_CATALOG:
			return { ...state, catalog: action.payload };
		case CLEAR_POS_DATA:
			return { ...state, ...initialState };
		case SET_DEVICE_STATUS:
			return { ...state, deviceStatus: action.payload };
		default:
			return state;
	}
};

export default posReducer;
