import { Dispatch } from 'react';
import {
	ADD_PRODUCT_TO_LANE,
	REMOVE_PRODUCT_FROM_LANE,
	REMOVE_ALL_PRODUCTS_FROM_LANE,
	SET_POS_VIEW,
	SET_PRODUCT_QUANTITY,
	SET_ORDER_ID,
	SET_CUSTOMER,
	SET_PAYMENT_MODES_WITH_AMOUNT,
	SET_TOTAL_AMOUNT_WITHOUT_TAX_AND_GRATUITY,
	SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY,
	SET_GRATUITY_PERCENTAGE,
	SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY,
	CLEAR_POS_DATA,
	SET_COUPON_INFO,
	SET_CATALOG,
	SET_DEVICE_STATUS,
	SET_TOTAL_AMOUNT_PAID,
	SET_SIDE_BAR_STATUS,
} from './PosActionTypes';

export const SetPosView: any =
	(
		view:
			| 'HOME'
			| 'TIP'
			| 'DISCOUNTS'
			| 'ADD_CUSTOMER'
			| 'CUSTOMER_LOYALTY'
			| 'PAYMENT_MODE_SELECTION'
			| 'SWIPE_CARD'
			| 'ADD_CARD_INFO'
			| 'PAYMENT_SUCCESSFULL'
	) =>
	async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_POS_VIEW, payload: view });
	};

export const SetSideBarStatus =
	(status: string) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_SIDE_BAR_STATUS, payload: status });
	};

export const AddProductToLane: any =
	(payload) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: ADD_PRODUCT_TO_LANE, payload });
	};

export const RemoveProductFromLane: any =
	(payload) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: REMOVE_PRODUCT_FROM_LANE, payload });
	};

export const RemoveAllProductsFromLane: any =
	() => async (dispatch: Dispatch<any>) => {
		dispatch({ type: REMOVE_ALL_PRODUCTS_FROM_LANE });
	};

export const SetProductQuantity: any =
	(payload) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_PRODUCT_QUANTITY, payload });
	};

export const SetOrderId: any =
	(payload: string) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_ORDER_ID, payload });
	};
export const SetPaymentModesWithAmount: any =
	(payload) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_PAYMENT_MODES_WITH_AMOUNT, payload });
	};
export const SetCustomer: any =
	(payload: any) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_CUSTOMER, payload });
	};
export const SetTotalAmountWithoutTaxAndGratuity: any =
	(payload: number) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_TOTAL_AMOUNT_WITHOUT_TAX_AND_GRATUITY, payload });
	};
export const SetTotalAmountWithTaxWithoutGratuity: any =
	(payload: number) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY, payload });
	};
export const SetGratuityPercentage: any =
	(payload: number) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_GRATUITY_PERCENTAGE, payload });
	};
export const SetTotalAmountWithTaxAndGratuity: any =
	(payload: number) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY, payload });
	};

export const SetTotalAmountPaid: any =
	(payload: number) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_TOTAL_AMOUNT_PAID, payload });
	};

export const ClearPosData: any = () => async (dispatch: Dispatch<any>) => {
	dispatch({ type: CLEAR_POS_DATA });
};

export const SetCouponInfo: any =
	(payload: any) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_COUPON_INFO, payload });
	};

export const SetCatalog: any =
	(payload: any) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_CATALOG, payload });
	};

export const SetDeviceStatus: any =
	(payload: any) => async (dispatch: Dispatch<any>) => {
		dispatch({ type: SET_DEVICE_STATUS, payload });
	};
