import Button from 'storybook-mui/build/components/Button';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetOrderById } from 'firebaseApis/orders';
import { useEffect, useState } from 'react';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { useHistory } from 'react-router-dom';
import { GetWorkStationById } from 'firebaseApis/locations';
import { IOrderData } from 'interfaces';
import LineItems from './LineItems';
import Transactions from './Transactions';
import Basic from './Basic';
import Tabs from 'storybook-mui/build/components/Tabs';
import Refunds from './Refunds';
import Voids from './Voids';
import TransactionsLogs from './TransactionsLogs';

function OrderDetails() {
	const [workStationData, setWorkStationData] = useState(null);
	const [orderData, setOrderData] = useState<IOrderData>(null);
	const [itemsData, setItemsData] = useState<[]>([]);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const dispatch: any = useDispatch();
	const history = useHistory();
	const params = useParams();

	const getData = async () => {
		dispatch(showLoading());
		try {
			const response = await GetOrderById(params.id);
			setOrderData(response);
			const itemsDataWithUniqueId = response.items.map((item: any, index) => {
				return {
					...item,
					id: index,
				};
			});
			setItemsData(itemsDataWithUniqueId);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (orderData) {
			GetWorkStationById(orderData?.work_station_id).then((response) => {
				setWorkStationData(response);
			});
		}
	}, [orderData]);

	return (
		orderData && (
			<div className='flex flex-col space-y-5'>
				<div className='flex flex-col my-5'>
					<div className='flex justify-between space-x-5'>
						<h1 className='text-3xl font-semibold text-gray-600'>Order Information</h1>
						<Button
							title='Back'
							variant='outlined'
							color='secondary'
							onClick={() => history.goBack()}
						/>
					</div>
					<Tabs
						TabsTiles={[
							'Basic',
							'Line Items',
							'Payments',
							'Refunds',
							'Voided Transactions',
							'Transactions Logs',
						]}
						TabsContent={[
							<Basic orderData={orderData} workStationData={workStationData} />,
							<LineItems itemsData={itemsData} />,
							<Transactions
								orderData={orderData}
								refreshOrder={getData}
								setSelectedIndex={setSelectedTabIndex}
							/>,
							<Refunds orderData={orderData} />,
							<Voids orderData={orderData} />,
							<TransactionsLogs orderData={orderData} />,
						]}
						selectedIndex={selectedTabIndex}
						setSelectedIndex={setSelectedTabIndex}
					/>
				</div>
			</div>
		)
	);
}

export default OrderDetails;
