import Toast, { ToastTypes } from 'components/ToastNotify';
import { MakePayment } from 'firebaseApis/payments';
import {
	CheckCouponValidity,
	IncrementCouponRedemptions,
} from 'firebaseApis/coupons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import {
	ClearPosData,
	SetCouponInfo,
	SetPosView,
	SetTotalAmountPaid,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';
import CreditCardModal from './CreditCardModal';
import PaymentModes from './PaymentModes';
import { CancelOrder } from 'firebaseApis/orders';

function Payment() {
	const dispatch: any = useDispatch();
	const {
		totalAmountWithTaxAndGratuity,
		totalAmountWithoutTaxAndGratuity,
		gratuityAmount,
		customer,
		orderId,
		view,
		currency,
		totalAmountPaid,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const [showVirtualCardModal, setShowVirtualCardModal] = useState(false);
	const [selectedVirtualCard, setSelectedVirtualCard] = useState(null);
	const [showCashPaymentModal, setShowCashPaymentModal] = useState(false);
	const [selectedCashPayment = 0, setSelectedCashPayment] = useState(null);
	const [customerGivenCash = 0, setCustomerGivenCash] = useState(null);
	const [selectedPaymentModes, setSelectedPaymentModes] = useState<
		IPaymentMode[]
	>([
		{
			name: 'Cash',
			value: 'cash',
			amount: Number(totalAmountWithTaxAndGratuity),
			index: 0,
			status: 'PENDING',
		},
	]);
	const [totalAmountDue, setTotalAmountDue] = useState(
		totalAmountWithTaxAndGratuity
	);
	const [
		amountPayedThroughAllPaymentModes,
		setAmountPayedThroughAllPaymentModes,
	] = useState({
		cash: 0,
		physicalCards: 0,
		virtualCards: 0,
		coupons: 0,
	});
	const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
	const addNewPaymentMode = () => {
		setSelectedPaymentModes([
			...selectedPaymentModes,
			{
				index: selectedPaymentModes.length,
				amount: Number(totalAmountDue),
				name: 'Virtual Card',
				value: 'virtualCards',
				status: 'PENDING',
			},
		]);
		setCustomerGivenCash(totalAmountDue);
	};

	const deletePaymentMode = (index: number) => {
		const newPaymentModes = [...selectedPaymentModes];
		newPaymentModes.splice(index, 1);
		setSelectedPaymentModes(newPaymentModes);
	};

	const updatePaymentModeType = (
		index: number,
		{ paymentModeValue, paymentModeName }
	) => {
		const tempPaymentModes: any = [...selectedPaymentModes];
		tempPaymentModes[index].value = paymentModeValue;
		tempPaymentModes[index].name = paymentModeName;
		setSelectedPaymentModes(tempPaymentModes);
	};

	const changePaymentModeAmount = (index: number, amount: number) => {
		const newPaymentModes = [...selectedPaymentModes];
		newPaymentModes[index].amount = Number(amount.toFixed(2));
		setSelectedPaymentModes(newPaymentModes);
	};

	const initiatePayment = async (paymentMode: IPaymentMode, index: any) => {
		try {
			dispatch(showLoading());
			// making payment
			const response = await MakePayment({
				orderId,
				tip: gratuityAmount,
				customerId: customer.id,
				transactionType: paymentMode.value,
				orderAmount: totalAmountWithTaxAndGratuity,
				coupon_code: paymentMode?.couponCode || '',
				paymentModes: {
					cash: paymentMode.value === 'cash' ? paymentMode.amount : 0,
					physicalCards:
						paymentMode.value === 'physicalCards' ? paymentMode.amount : 0,
					virtualCards:
						paymentMode.value === 'virtualCards' ? paymentMode.amount : 0,
					coupons: paymentMode.value === 'coupons' ? Number(paymentMode.amount) : 0,
				},
			});

			if (response?.data?.payment_status === 'success') {
				dispatch(hideLoading());
				Toast({
					type: ToastTypes.SUCCESS,
					title: 'Payment Successful',
				});

				// updating payment mode status
				const newPaymentModes = [...selectedPaymentModes];
				newPaymentModes[index].status = 'SUCCESS';
				newPaymentModes[index].amount = paymentMode.amount;

				setSelectedPaymentModes(newPaymentModes);

				// updating total amount payed
				dispatch(
					SetTotalAmountPaid(Number(totalAmountPaid) + Number(paymentMode.amount))
				);
				setTotalAmountDue(totalAmountDue - paymentMode.amount);

				if (totalAmountDue - paymentMode.amount < 0.1) {
					dispatch(SetPosView('PAYMENT_SUCCESSFULL'));
				}

				// based on the payment mode, update the amount payed through all payment modes
				if (paymentMode.value === 'cash') {
					setAmountPayedThroughAllPaymentModes({
						...amountPayedThroughAllPaymentModes,
						cash: amountPayedThroughAllPaymentModes.cash + paymentMode.amount,
					});
					setShowCashPaymentModal(false);
					setSelectedCashPayment(null);
					setCustomerGivenCash(0);
				}

				if (paymentMode.value === 'physicalCards') {
					setAmountPayedThroughAllPaymentModes({
						...amountPayedThroughAllPaymentModes,
						physicalCards:
							amountPayedThroughAllPaymentModes.physicalCards + paymentMode.amount,
					});
				}
				if (paymentMode.value === 'coupons') {
					setAmountPayedThroughAllPaymentModes({
						...amountPayedThroughAllPaymentModes,
						coupons:
							amountPayedThroughAllPaymentModes.coupons + Number(paymentMode.amount),
					});

					// updating maximum redemtions for the coupon
					await IncrementCouponRedemptions(paymentMode.couponCode);
				}
			} else {
				Toast({
					title: 'Payment Failed',
					type: ToastTypes.ERROR,
				});
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const setCreditCardTransactionSuccess = () => {
		setShowVirtualCardModal(false);
		const newPaymentModes = [...selectedPaymentModes];
		newPaymentModes[selectedVirtualCard.index].status = 'SUCCESS';
		setSelectedPaymentModes(newPaymentModes);

		// updating total amount payed for virtual card
		setAmountPayedThroughAllPaymentModes({
			...amountPayedThroughAllPaymentModes,
			virtualCards:
				amountPayedThroughAllPaymentModes.virtualCards + selectedVirtualCard.amount,
		});

		// updating total amount payed
		dispatch(SetTotalAmountPaid(totalAmountPaid + selectedVirtualCard.amount));
		setTotalAmountDue(totalAmountDue - selectedVirtualCard.amount);

		if (totalAmountDue - selectedVirtualCard.amount < 0.1) {
			dispatch(SetPosView('PAYMENT_SUCCESSFULL'));
		}
	};

	const applyCoupon = async (paymentMode: IPaymentMode, index: any) => {
		dispatch(showLoading());
		const response = await CheckCouponValidity(paymentMode.couponCode);
		dispatch(hideLoading());
		if (response.success) {
			Toast({ title: 'Coupon Applied Successfully', type: ToastTypes.SUCCESS });

			let totalAmountAfterDiscount = totalAmountWithoutTaxAndGratuity;
			if (response.data.Discount_Type === 'Percentage') {
				totalAmountAfterDiscount =
					totalAmountWithoutTaxAndGratuity -
					(totalAmountWithoutTaxAndGratuity * response.data.Discount_Percentage) /
						100;
			} else {
				totalAmountAfterDiscount =
					totalAmountWithoutTaxAndGratuity - response.data.Discount_Amount;
			}

			// updating the coupon in redux store
			let amountDiscounted =
				totalAmountWithoutTaxAndGratuity - totalAmountAfterDiscount;
			dispatch(
				SetCouponInfo({
					couponCode: paymentMode.couponCode,
					discountPercentage: response.data.Discount_Percentage,
					discountAmount: response.data.Discount_Amount,
					discountType: response.data.Discount_Type,
					amountDiscounted,
				})
			);

			initiatePayment(
				{
					...paymentMode,
					amount: Number(amountDiscounted.toFixed(2)),
				},
				index
			);
		} else {
			Toast({ title: response.message, type: ToastTypes.ERROR });
		}
	};

	const isEnterCouponCodeAlreadyAppliedOrNot = (couponCode) => {
		if (couponCode?.length > 0) {
			const couponAlreadyApplied = selectedPaymentModes.filter((item) => {
				return item.value === 'coupons' && item.couponCode === couponCode;
			});
			return couponAlreadyApplied.length > 1;
		} else {
			return false;
		}
	};

	const getAmountColumnContent = (paymentMode: IPaymentMode, index: any) => {
		let contentToRender = <></>;

		if (paymentMode.value === 'coupons') {
			contentToRender = (
				<div className='flex gap-5'>
					<div>
						{paymentMode.status !== 'SUCCESS' ? (
							<div className='flex gap-5 items-center'>
								<div>
									<input
										type='text'
										className='border h-12 focus:outline-none px-5 border-gray-500'
										value={paymentMode.couponCode || ''}
										onChange={(e) => {
											const newPaymentModes = [...selectedPaymentModes];
											newPaymentModes[index].couponCode = e.target.value;
											setSelectedPaymentModes(newPaymentModes);
										}}
										placeholder='Coupon Code'
										min={0}
										max={totalAmountDue}
									/>
									{isEnterCouponCodeAlreadyAppliedOrNot(paymentMode.couponCode) && (
										<h1 className='text-red-500'>Coupon already applied</h1>
									)}
								</div>
								<Button
									title='Apply'
									onClick={() => {
										applyCoupon(paymentMode, index);
									}}
									variant='contained'
									color='secondary'
									disabled={
										!paymentMode.couponCode ||
										paymentMode.couponCode === '' ||
										isEnterCouponCodeAlreadyAppliedOrNot(paymentMode.couponCode)
									}
								/>
							</div>
						) : (
							<div className='flex flex-col'>
								<h1>Coupon : {paymentMode.couponCode}</h1>
								<h1>Amount : {paymentMode?.amount}</h1>
							</div>
						)}
					</div>
				</div>
			);
		} else {
			contentToRender = (
				<div className='flex gap-5 items-center'>
					{paymentMode.status === 'SUCCESS' && (
						<h1>
							{currency} {paymentMode.amount}
						</h1>
					)}
					{paymentMode.status !== 'SUCCESS' && (
						<div className='flex items-center gap-5'>
							<div>
								<input
									type='number'
									className='border h-10 focus:outline-none px-5 border-gray-500 w-40'
									value={paymentMode.amount || ''}
									onChange={(e) =>
										changePaymentModeAmount(index, Number(e.target.value))
									}
									min={0}
									max={totalAmountDue}
								/>
								<h1 className='text-red-500'>
									{paymentMode.amount > totalAmountDue &&
										`Amount must be less than ${currency} ${totalAmountDue}`}
								</h1>
							</div>
							<Button
								title='Pay'
								onClick={() => {
									if (paymentMode.value === 'cash') {
										// physical card , cash will be directly triggered
										setShowCashPaymentModal(true);
										setSelectedCashPayment({
											...paymentMode,
											index,
										});
									} else {
										// virtual card
										setSelectedVirtualCard({
											...paymentMode,
											amount: paymentMode.amount,
											index: index,
										});
										setShowVirtualCardModal(true);
									}
								}}
								variant='contained'
								color='secondary'
								disabled={
									!paymentMode.amount ||
									paymentMode.amount <= 0 ||
									paymentMode.amount > totalAmountDue
								}
								type='submit'
							/>
						</div>
					)}
				</div>
			);
		}

		return contentToRender;
	};

	const cancelOrder = async () => {
		try {
			dispatch(showLoading());
			const response: any = await CancelOrder(orderId.toString());
			dispatch(hideLoading());
			if (response.success) {
				dispatch(ClearPosData());
				Toast({ title: response.message, type: ToastTypes.SUCCESS });
			} else {
				Toast({ title: response.message, type: ToastTypes.ERROR });
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: error?.message, type: ToastTypes.ERROR });
		}
	};

	return (
		<Modal
			title='Payment'
			closeIcon={true}
			content={
				<div className='flex gap-5 justify-between flex-col lg:flex-row md:flex-row xl:flex-row'>
					<div className='relative'>
						{selectedPaymentModes.length > 0 && (
							<table className='table-auto'>
								<thead className='bg-gray-500 text-white py-5'>
									<tr className='text-left'>
										<th className='px-10 py-5'>Payment Mode</th>
										<th className='pr-10'>Amount</th>
										<th className='pr-10'>Status</th>
										<th className='pr-10'>Delete</th>
									</tr>
								</thead>
								<tbody>
									{selectedPaymentModes.map((paymentMode, index) => (
										<tr className='text-start border'>
											<td className='px-10'>
												<select
													value={paymentMode.value}
													onChange={(e: any) =>
														updatePaymentModeType(index, {
															paymentModeValue: e.target.value,
															paymentModeName: e.target.options[e.target.selectedIndex].text,
														})
													}
													disabled={paymentMode.status === 'SUCCESS'}
													className='border h-10 focus:outline-none px-5 border-gray-500 w-40'
												>
													<option value='cash'>Cash</option>
													<option value='virtualCards'>Virtual Card</option>
												</select>
											</td>
											<td>
												<div className='flex gap-5 items-center mr-10 my-5'>
													{getAmountColumnContent(paymentMode, index)}
												</div>
											</td>

											<td>
												<h1 className=''>{paymentMode.status}</h1>
											</td>
											<td>
												{paymentMode.status !== 'SUCCESS' && (
													<Icon
														icon={IconTypes.Delete}
														onClick={() => {
															deletePaymentMode(index);
														}}
														className='cursor-pointer'
													/>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}

						{totalAmountDue > 0 && (
							<h1
								className={`text-2xl mt-5 cursor-pointer border-dashed w-max p-5 
								border-2 border-gray-500 
								   ${
												selectedPaymentModes.filter((sm) => sm.status === 'PENDING')
													.length && 'bg-gray-300 pointer-events-none border-none text-white'
											}
								`}
								onClick={() => {
									addNewPaymentMode();
								}}
							>
								Add Payment Method
							</h1>
						)}
					</div>

					<div className='shadow border border-gray-300 p-5 flex flex-col gap-2 w-96 '>
						<div className='shadow-sm border p-2 flex flex-col gap-2'>
							<h1 className='text-xl font-semibold text-info-500'>Order Amount</h1>
							<hr />

							<h1>
								Sub Total : {currency}{' '}
								{Number(totalAmountWithoutTaxAndGratuity).toFixed(2)}
							</h1>
							<h1>
								Gratuity : {currency} {Number(gratuityAmount).toFixed(2)}
							</h1>
							<hr />
							<h1 className='font-semibold p-2'>
								Total Amount : {currency} {Number(totalAmountDue).toFixed(2)}
							</h1>
						</div>
						<div className='shadow-sm border flex flex-col gap-2 p-2 text-md'>
							<h1 className='text-xl font-semibold text-info-500'>Paid Amount</h1>
							<hr />
							<h1>
								Cash : {currency}{' '}
								{Number(amountPayedThroughAllPaymentModes.cash).toFixed(2)}
							</h1>
							<h1>
								Physical Card : ${' '}
								{Number(amountPayedThroughAllPaymentModes.physicalCards).toFixed(2)}
							</h1>
							<h1>
								Virtual Card : ${' '}
								{amountPayedThroughAllPaymentModes.virtualCards.toFixed(2)}
							</h1>
							<h1>
								Coupon : ${' '}
								{Number(amountPayedThroughAllPaymentModes.coupons).toFixed(2)}
							</h1>
							<hr />
							<h1 className='font-bold text-info-500'>
								Total Amount Paid : {currency} {Number(totalAmountPaid).toFixed(2)}
							</h1>
							<h1 className='font-bold text-info-500'>
								Pending Amount : {currency} {Number(totalAmountDue).toFixed(2)}
							</h1>
						</div>
					</div>

					{showVirtualCardModal && (
						<CreditCardModal
							showCreditCardModal={showVirtualCardModal}
							setShowCreditCardModal={setShowVirtualCardModal}
							amount={selectedVirtualCard.amount}
							setCreditCardTransactionSuccess={setCreditCardTransactionSuccess}
						/>
					)}

					{showCashPaymentModal && (
						<Modal
							title='Cash Payment'
							content={
								<>
									<div className='flex flex-col gap-5'>
										<div className='flex flex-col gap-2'>
											<h1>Amount Given By Customer</h1>
											<input
												type='number'
												value={customerGivenCash}
												onChange={(e) => {
													setCustomerGivenCash(e.target.value);
												}}
												min={0}
												className='border-2 h-10 w-full px-5 outline-none border-primary-500'
											/>
											{customerGivenCash < selectedCashPayment.amount &&
												selectedCashPayment.amount && (
													<h1 className='text-red-500'>
														Amount must be greater than {currency}
														{selectedCashPayment.amount}
													</h1>
												)}
											{customerGivenCash >= selectedCashPayment.amount && (
												<h1>
													Return Amount : ${' '}
													{(customerGivenCash - selectedCashPayment.amount).toFixed(2)}
												</h1>
											)}
										</div>
										<div className='flex justify-end gap-5'>
											<Button
												title='Cancel'
												onClick={() => {
													setSelectedCashPayment(null);
													setShowCashPaymentModal(false);
													setCustomerGivenCash(0);
												}}
												variant='outlined'
												color='primary'
											/>
											<Button
												title='Pay'
												onClick={() =>
													initiatePayment(selectedCashPayment, selectedCashPayment.index)
												}
												variant='contained'
												color='primary'
												disabled={
													customerGivenCash < selectedCashPayment.amount ||
													!customerGivenCash
												}
											/>
										</div>
									</div>
								</>
							}
							modalId='cashPaymentModal'
							open={showCashPaymentModal}
							setOpen={setShowCashPaymentModal}
							size='sm'
						/>
					)}

					{showCancelOrderModal && (
						<Modal
							title='Cancel Order'
							content={
								<>
									<h1>Are you sure you want to cancel this order ?</h1>
									<div className='flex justify-end gap-5'>
										<Button
											title='No'
											onClick={() => setShowCancelOrderModal(false)}
											variant='outlined'
											color='primary'
										/>
										<Button
											title='Yes'
											onClick={() => cancelOrder()}
											variant='contained'
											color='primary'
										/>
									</div>
								</>
							}
							modalId='Cancel Order'
							open={showCancelOrderModal}
							setOpen={setShowCancelOrderModal}
							size='sm'
						/>
					)}
				</div>
			}
			modalId={''}
			open={view === 'PAYMENT'}
			setOpen={() => setShowCancelOrderModal(true)}
		/>
	);
}

export default Payment;

export interface IPaymentMode {
	name: string;
	value: 'cash' | 'physicalCards' | 'virtualCards' | 'coupons';
	status: 'SUCCESS' | 'PENDING';
	amount: number;
	couponCode?: string;
	index?: number;
}
