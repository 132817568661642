import {
	getClientDB,
	getOrganisationIdFirebase,
	getUser,
} from 'api/AxiosManager';
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	where,
} from 'firebase/firestore/lite';
import moment from 'moment';

export const SaveLocation = async (
	payload: any,
	locationId: any,
	organizationId: any
) => {
	try {
		let result;
		const locationSnapshots = await getDocs(
			query(
				collection(getClientDB(), `/${organizationId}/Locations/Locations`),
				where('Location_Name', '==', payload.Location_Name)
			)
		);

		if (locationSnapshots.size === 0) {
			await setDoc(
				doc(getClientDB(), `/${organizationId}/Locations/Locations`, locationId),
				{
					...payload,
					Created_At: moment().toString(),
				}
			);

			result = {
				success: true,
			};
		} else {
			result = {
				success: false,
				message: 'Location already exist',
			};
		}

		return result;
	} catch (error) {
		return error;
	}
};

export const GetLocationInfoById = async () => {
	try {
		const locationId =
			getUser().Work_Station_And_Terminal_Information.Location_Id;
		const result = await getDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Locations/Locations`,
				locationId
			)
		);
		return result.data();
	} catch (error) {
		return error;
	}
};

export const GetWorkStationById = async (workstationId: any) => {
	try {
		const result = await getDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/POS/Work_Stations`,
				workstationId
			)
		);
		return result.data();
	} catch (error) {
		return error;
	}
};

export const GetOrganizationInfoById = async () => {
	try {
		const organizationId = getOrganisationIdFirebase();
		const result = await getDoc(
			doc(
				getClientDB(),
				`/${organizationId}/Organization/Organization/${organizationId}`
			)
		);
		return result.data();
	} catch (error) {
		return error;
	}
};
