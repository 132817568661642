import Items from './Components/Items';
import Tip from './Components/Gratuity';
import AddCustomer from './Components/SelectCustomer';
import { useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import Payment from './Components/Payment';

import { IPos } from 'reducers/POS';
import PaymentSuccess from './Components/PaymentSuccess';

function Home() {
	const { view }: IPos = useSelector((store: RootStore) => store.posReducer);

	return (
		<>
			<Items />
			{view === 'GRATUITY' && <Tip />}
			{view === 'ADD_CUSTOMER' && <AddCustomer />}
			{view === 'PAYMENT' && <Payment />}
			{view === 'PAYMENT_SUCCESSFULL' && <PaymentSuccess />}
		</>
	);
}

export default Home;
