import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import {
	GetAuthorizePaymentStatus,
	RefundAmount,
	VoidTransaction,
} from 'firebaseApis/payments';
import { IOrderData, IPaymentDetailsInOrder } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import Button from 'storybook-mui/build/components/Button';

function Transactions({
	orderData,
	refreshOrder,
	setSelectedIndex,
}: ITransactions) {
	const dispatch: any = useDispatch();
	const [tempDataSource, setTempdataSource] = useState<any>([]);
	const { currency }: IPos = useSelector((state: any) => state.posReducer);
	const getStatus = async () => {
		setTempdataSource([]);
		try {
			orderData?.payment_details?.forEach(
				async (payment: IPaymentDetailsInOrder, index: number) => {
					let tempStatus = 'Unsettled';

					if (payment.transactionType === 'virtualCard') {
						dispatch(showLoading());
						const response = await GetAuthorizePaymentStatus(payment.transactionid);
						dispatch(hideLoading());

						if (response?.data?.transactionStatus === 'settledSuccessfully') {
							tempStatus = 'Settled';
						}
						if (response?.data?.transactionStatus === 'voided') {
							tempStatus = 'Voided';
						}
						if (response?.data?.transactionStatus === 'refundPending') {
							tempStatus = 'Refund Pending';
						}
						if (response?.data?.transactionStatus === 'refundFailed') {
							tempStatus = 'Refund Failed';
						}
						if (response?.data?.transactionStatus === 'refundSuccess') {
							tempStatus = 'Refund Success';
						}
						if (
							orderData?.refund_details?.find(
								(refund: any) => refund?.ref_txn_id === payment?.transactionid
							)
						) {
							tempStatus = 'Refunded';
						}
						setTempdataSource((prev) => [
							...prev,
							{
								...payment,
								id: index,
								status: tempStatus,
							},
						]);
					} else {
						tempStatus = 'Settled';
						if (
							orderData?.refund_details?.find(
								(refund: any) => refund?.ref_txn_id === payment?.transactionid
							)
						) {
							tempStatus = 'Refunded';
						}
						setTempdataSource((prev: any) => [
							...prev,
							{
								...payment,
								id: index,
								status: tempStatus,
							},
						]);
					}
				}
			);
		} catch (error) {
			setTempdataSource([]);
			dispatch(hideLoading());
		}
	};

	const refund = async (data: IPaymentDetailsInOrder) => {
		try {
			dispatch(showLoading());
			const response = await RefundAmount({
				amount: data.authorized_amount,
				transactionId: data.transactionid,
				transactionType: data.transactionType,
				cardNumber: data.card_number,
				orderId: orderData.id,
			});
			dispatch(hideLoading());
			if (response.status === 'success') {
				Toast({ title: 'Refund Successfull', type: ToastTypes.SUCCESS });
				setSelectedIndex(3);
				refreshOrder();
			} else {
				Toast({ title: 'Refund failed', type: ToastTypes.ERROR });
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const voidTransaction = async (data: IPaymentDetailsInOrder) => {
		try {
			dispatch(showLoading());
			const response = await VoidTransaction({
				amount: data.authorized_amount,
				transactionId: data.transactionid,
				transactionType: data.transactionType,
				cardNumber: data.card_number,
				orderId: orderData.id,
			});
			dispatch(hideLoading());
			if (response.data.void_status === 'success') {
				Toast({ title: 'Void Successfull', type: ToastTypes.SUCCESS });
				setSelectedIndex(4);
				refreshOrder();
				getStatus();
			} else {
				Toast({ title: 'Refund failed', type: ToastTypes.ERROR });
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const columns = [
		{
			headerName: 'Transaction Type',
			field: 'transactionType',
			flex: 1,
			renderCell: (data: any) => {
				let type = data.row.transactionType;

				if (type === 'virtualCard') {
					type = 'Virtual Card';
				} else if (type === 'cash') {
					type = 'Cash';
				} else if (type === 'physicalCard') {
					type = 'Physical Card';
				} else {
					type = 'Coupons';
				}
				return <>{type}</>;
			},
		},
		{
			headerName: 'Transaction ID',
			field: 'transactionid',
			flex: 1,
		},
		{
			headerName: 'Amount',
			field: 'authorized_amount',
			flex: 1,
			renderCell: (data: any) => {
				return (
					<>
						{' '}
						{currency} {data.row.authorized_amount}
					</>
				);
			},
		},
		{
			headerName: 'Status',
			field: 'status',
			flex: 1,
		},
		{
			headerName: 'Actions',
			field: 'actions',
			flex: 1,
			renderCell: (data: any) => {
				if (data.row.status === 'Settled') {
					return (
						<Button
							title='Refund Amount'
							variant='outlined'
							color='secondary'
							onClick={() => refund(data.row)}
						/>
					);
				} else if (data.row.status === 'Unsettled') {
					return (
						<Button
							title='Void Transaction'
							variant='outlined'
							color='secondary'
							onClick={() => voidTransaction(data.row)}
						/>
					);
				} else {
					return <></>;
				}
			},
		},
	];

	useEffect(() => {
		setTempdataSource([]);
		getStatus();
	}, []);

	return (
		<div className='mt-5'>
			<MUIGridGloabl
				columns={columns}
				dataSource={tempDataSource ?? []}
				rowCount={tempDataSource?.length}
			/>
		</div>
	);
}

export default Transactions;
export interface ITransactions {
	orderData: IOrderData;
	refreshOrder: () => void;
	setSelectedIndex: (index: number) => void;
}
