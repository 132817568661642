import React, { useEffect } from 'react';
import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GetPosContacts } from 'firebaseApis/contacts';
import Button from 'storybook-mui/build/components/Button';
import { useHistory } from 'react-router-dom';

function Customers() {
	const history = useHistory();
	const [contactsData, setcontactsData] = React.useState<[]>([]);
	const dispatch: any = useDispatch();
	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 1,
		},
		{
			headerName: 'First Name',
			field: 'First_Name',
			flex: 1,
		},
		{
			headerName: 'Last Name',
			field: 'Last_Name',
			flex: 1,
		},
		{
			headerName: 'Email',
			field: 'Email',
			flex: 1,
		},
		{
			headerName: 'Phone Number',
			field: 'Phone_Number',
			flex: 1,
		},
	];

	const getData = async () => {
		dispatch(showLoading());
		try {
			const response = await GetPosContacts();
			setcontactsData(response);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<div>
			<div className='flex justify-between items-start my-5'>
				<h1 className='text-3xl font-semibold text-gray-600'>Customers</h1>
				<Button
					title='Add Customer'
					onClick={() => {
						window.open(`${localStorage.getItem('redirectionLink')}/Dynamic/Contacts/New`, '_blank');
					}}
					variant='contained'
					color='primary'
				/>
			</div>
			<MUIGridGloabl
				columns={columns}
				dataSource={contactsData ?? []}
				rowCount={contactsData.length}
			/>
		</div>
	);
}

export default Customers;
