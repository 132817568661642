import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { SetPosView } from 'reducers/POS/PosActions';
import Modal from 'storybook-mui/build/components/Modal';
import AddNew from './AddNew';
import SearchExisting from './SearchExisting';

function AddCustomer() {
	const { view } = useSelector((store: RootStore) => store.posReducer);
	const [modelView, setModelView] = useState<'SELECTION' | 'ADD' | 'SEARCH'>(
		'SELECTION'
	);
	const dispatch: any = useDispatch();
	const closeModel = () => {
		dispatch(SetPosView('ITEMS'));
		setModelView('SELECTION');
	};

	return (
		<Modal
			size='md'
			title={
				<h1 className=''>
					{modelView === 'SELECTION' || modelView === 'ADD'
						? 'Add Customer'
						: 'Search Existing Customer'}
				</h1>
			}
			modalId=''
			open={view === 'ADD_CUSTOMER'}
			setOpen={() => closeModel()}
			closeIcon
			content={
				<div className='flex flex-col space-y-5'>
					{modelView === 'SELECTION' && (
						<div className='space-x-5 flex text-center'>
							<h1
								className='p-5 text-3xl border shadow-md border-gray-300  cursor-pointer w-1/2 rounded'
								onClick={() => setModelView('ADD')}
							>
								ADD CUSTOMER
							</h1>
							<h1
								className='p-5 text-3xl border shadow-md border-gray-300   cursor-pointer w-1/2 rounded'
								onClick={() => setModelView('SEARCH')}
							>
								SEARCH CUSTOMER
							</h1>
						</div>
					)}
					{modelView === 'ADD' && <AddNew setModelView={setModelView} />}
					{modelView === 'SEARCH' && <SearchExisting setModelView={setModelView} />}
				</div>
			}
		/>
	);
}

export default AddCustomer;
