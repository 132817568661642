export const SET_DEVICE_STATUS = 'SET_DEVICE_STATUS';
export const SET_POS_VIEW = "SET_POS_VIEW";
export const SET_CATALOG = "SET_CATALOG";
export const ADD_PRODUCT_TO_LANE = "ADD_PRODUCT_TO_LANE";
export const REMOVE_PRODUCT_FROM_LANE = "REMOVE_PRODUCT_FROM_LANE";
export const SET_PRODUCT_QUANTITY = "SET_PRODUCT_QUANTITY";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const REMOVE_ALL_PRODUCTS_FROM_LANE = "REMOVE_ALL_PRODUCTS_FROM_LANE";
export const SET_PAYMENT_MODES_WITH_AMOUNT = "SET_PAYMENT_MODES_WITH_AMOUNT";
export const SET_COUPON_INFO = "SET_COUPON_INFO";
export const SET_TOTAL_AMOUNT_WITHOUT_TAX_AND_GRATUITY = "SET_TOTAL_AMOUNT_WITHOUT_TAX_AND_GRATUITY";
export const SET_TOTAL_AMOUNT_WITH_TAX_WITHOUT_GRATUITY = "SET_TOTAL_AMOUNT_WITH_TAX";
export const SET_GRATUITY_PERCENTAGE = "SET_GRATUITY_PERCENTAGE";
export const SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY = "SET_TOTAL_AMOUNT_WITH_TAX_AND_GRATUITY";
export const SET_TOTAL_AMOUNT_PAID = "SET_TOTAL_AMOUNT_PAID";
export const CLEAR_POS_DATA = "CLEAR_POS_DATA";
export const SET_SIDE_BAR_STATUS = "SET_SIDE_BAR_STATUS";
