import React from 'react';
import Tabs from 'storybook-mui/build/components/Tabs';
import OrdersPagination from './OrdersPagination';
function Reports() {
	const [selectedTab, setSelectedTab] = React.useState(0);
	return (
		<div>
			<Tabs
				selectedIndex={selectedTab}
				setSelectedIndex={setSelectedTab}
				TabsTiles={['Orders']}
				TabsContent={[<OrdersPagination />]}
			/>
		</div>
	);
}

export default Reports;
