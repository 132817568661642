import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import {
	collection,
	doc,
	getDocs,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import moment from 'moment';

export const CheckCouponValidity = async (couponCode: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Coupons`),
			where('Coupon_Code', '==', couponCode)
		);
		const resultSnapshots = await getDocs(qry);
		if (resultSnapshots.docs.length > 0) {
			// disable es lint
			const resultArray: any = resultSnapshots.docs.map((document) => ({
				...document.data(),
				id: document.id,
			}));
			const couponMatched = resultArray[0];
			if (!couponMatched.Is_Active) {
				return {
					success: false,
					message: 'Coupon is not active',
				};
			}
			if (couponMatched.Maximum_Redemptions === couponMatched.Redemptions_Done) {
				return {
					success: false,
					message: 'Coupon has reached maximum redemptions',
				};
			}
			if (couponMatched.Duration === 'Limited') {
				if (moment(couponMatched.End_Date).isBefore(moment())) {
					return {
						success: false,
						message: 'Coupon has expired',
					};
				}
			}
			return {
				success: true,
				data: couponMatched,
				message: 'Coupon applied successfully',
			};
		}
		return {
			success: false,
			data: '',
			message: 'Invalid Coupon Code',
		};
	} catch (error) {
		return error;
	}
};

export const IncrementCouponRedemptions = async (couponCode: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/Coupons`),
			where('Coupon_Code', '==', couponCode)
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray: any = resultSnapshots.docs.map((document) => ({
			...document.data(),
			id: document.id,
		}));
		const couponMatched = resultArray[0];
		const updatedCoupon = {
			...couponMatched,
			Redemptions_Done: couponMatched.Redemptions_Done + 1,
		};
		await setDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/Coupons`,
				couponMatched.id
			),
			updatedCoupon
		);
	} catch (error) {
		return error;
	}
};
