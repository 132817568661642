import { IProductInterface } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { IPos } from 'reducers/POS';
import {
	AddProductToLane,
	RemoveProductFromLane,
} from 'reducers/POS/PosActions';

function Product({
	product,
	index,
	hoveredItemId,
	setHoveredItemId,
	setShowProductInfoModal,
	setSelectedProduct,
}: IProductProps) {
	const dispatch = useDispatch();
	const { laneItems, currency }: IPos = useSelector(
		(state: any) => state.posReducer
	);

	const isAlreadyinlaneItems = laneItems.find(
		(item: IProductInterface) => item.product_id === product.product_id
	);
	let imagePath: string;
	if (product?.image?.length) {
		imagePath = product?.image[0]?.file_path;
	} else {
		imagePath = '';
	}
	return (
		<div
			key={index}
			className={`bg-white cursor-pointer rounded-lg flex flex-col justify-between cursor-pointer${
				isAlreadyinlaneItems
					? ' border-info-500 border-2'
					: 'custom-card-shadow hover:drop-shadow-2xl'
			}`}
			onClick={() => {
				if (!isAlreadyinlaneItems) {
					dispatch(AddProductToLane({ ...product, quantity: 1 }));
					setShowProductInfoModal(false);
				} else {
					dispatch(RemoveProductFromLane(product.name));
				}
			}}
			onMouseEnter={() => setHoveredItemId(product.product_id)}
			onMouseLeave={() => setHoveredItemId('')}
		>
			<div className='p-4'>
				<img
					src={imagePath}
					alt=''
					className='h-52 w-full rounded-lg object-contain aspect-auto'
				/>
			</div>

			<div className='p-4 flex flex-col gap-1'>
				<h1 className='text-xl w-full  text-info-500 font-semibold'>
					{product.name} {product?.product_id}
				</h1>

				<p
					className={`text-gray-500 overflow-auto transform transition-all duration-500 text-md ${
						hoveredItemId === product.product_id ? 'h-32' : 'h-0'
					}`}
				>
					{product.description}
				</p>
				<h1 className='font-semibold text-xl text-info-500'>
					{currency} {product.product_price.toFixed(2)}
				</h1>
			</div>
		</div>
	);
}

export default Product;

export interface IProductProps {
	product: IProductInterface;
	index: number;
	hoveredItemId: string;
	setHoveredItemId: any;
	setShowProductInfoModal: any;
	setSelectedProduct: any;
}
