import {
	cloudBaseUrl,
	getOrganisationIdFirebase,
	getUser,
	create,
	getClientDB,
} from 'api/AxiosManager';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const MakePayment = async ({
	customerId,
	orderId,
	values, // credicard values
	paymentModes,
	tip,
	transactionType,
	orderAmount,
	coupon_code,
}: any) => {
	try {
		const payload = {
			Organization_Id: getOrganisationIdFirebase(),
			customer_id: customerId || '',
			Payment_Terminal_Id:
				getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal,
			orderid: orderId,
			total: Number(Number(orderAmount).toFixed(2)),
			tip: Number(Number(tip).toFixed(2)),
			coupon_code: coupon_code || '',
			paymentModes: paymentModes,
			cardNumber: values?.cardNumber || '',
			expirationDate: `${values?.expiryYear}-${values?.expiryMonth}`,
			cardCode: values?.cvv || '',
			customer: {
				firstName: values?.firstName || '',
				lastName: values?.lastName || '',
				zip: values?.zip || '',
				country: values?.country || '',
			},
			env: getUser().Work_Station_And_Terminal_Information.payment_env,
			transactionType: transactionType || '',
		};

		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/payment?Organization_Id=${getOrganisationIdFirebase()}`,
			payload
		);
		return result.data;
	} catch (error) {
		return error;
	}
};

export const RefundAmount = async ({
	orderId,
	amount,
	transactionType,
	transactionId,
	cardNumber,
}: any) => {
	try {
		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/refund?Organization_Id=${getOrganisationIdFirebase()}`,
			{
				Organization_Id: getOrganisationIdFirebase(),
				Payment_Terminal_Id:
					getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal,
				orderid: orderId,
				Currency: '$',
				RequestedAmount: amount,
				ServiceID: '',
				transactionType,
				TransactionID: transactionId,
				cardNumber,
				env: getUser().Work_Station_And_Terminal_Information.payment_env,
			}
		);
		return result.data;
	} catch (error) {
		return error;
	}
};

export const VoidTransaction = async ({
	orderId,
	amount,
	transactionType,
	transactionId,
	cardNumber,
}: any) => {
	try {
		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/void?Organization_Id=${getOrganisationIdFirebase()}`,
			{
				Organization_Id: getOrganisationIdFirebase(),
				Payment_Terminal_Id:
					getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal,
				orderid: orderId,
				Currency: '$',
				RequestedAmount: amount,
				ServiceID: '',
				transactionType,
				TransactionID: transactionId,
				cardNumber,
				env: getUser().Work_Station_And_Terminal_Information.payment_env,
			}
		);
		return result.data;
	} catch (error) {
		return error;
	}
};

export const GetAuthorizePaymentStatus = async (transactionId: string) => {
	try {
		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/txnstatus?Organization_Id=${getOrganisationIdFirebase()}`,
			{
				Organization_Id: getOrganisationIdFirebase(),
				TransactionID: transactionId,
				env: getUser().Work_Station_And_Terminal_Information.payment_env,
			}
		);
		return result.data;
	} catch (error) {
		return error;
	}
};

export const GetTransactionLogsByOrderId = async (orderId: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/pg_logs`),
			where('orderid', '==', Number(orderId)),
			where('action', '==', 'Intiate Payment')
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray = resultSnapshots.docs.map((document) => ({
			...document.data(),
			id: document.id,
		}));
		return resultArray;
	} catch (error) {
		return {
			error: true,
			message: error.message,
			data: [],
		};
	}
};
