import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import CryptoJS from 'crypto-js';
export interface ISiteConfigConstants {
	REACT_APP_AUTHORIZATION: string;
	REACT_APP_BASE_URL: string;
	REACT_APP_CLOUD_URL: string;
	REACT_APP_EMAIL_URL: string;
	REACT_APP_ImageEditor: string;
	REACT_APP_MODE: string;
	REACT_APP_SMS_URL: string;
	REACT_APP_SOCIAL_FEEDS_URL: string;
	REACT_APP_SOCIAL_URL: string;
	REACT_APP_TWILIO_APIS_URL: string;
	REACT_APP_VERIFONE_ENCRYPTION_KEY: string;
	REACT_APP_VOICE_URL: string;
}

export const getUser = () => {
	const token = localStorage.getItem('token');
	if (token !== undefined && token !== null) {
		try {
			const bytes = CryptoJS.AES.decrypt(token.replaceAll(' ', '+'), 'LEAP360');
			const decreptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			return decreptedToken;
		} catch (error) {
			localStorage.clear();
			return null;
		}
	} else {
		localStorage.clear();
		return {};
	}
};

export const siteConfigConstants = (): ISiteConfigConstants =>
	getUser()?.Site_Config ?? {};

export const cloudBaseUrl = (): string =>
	siteConfigConstants().REACT_APP_CLOUD_URL;

export const getUserName = () => {
	const userString = getUser();
	if (userString) {
		const user = userString?.User_And_Organization_Information;
		return `${user.fName} ${user.lName}`;
	}
	return {};
};

export const getOrganisationIdFirebase = () =>
	getUser()?.User_And_Organization_Information?.organisationPathFirebase;

export const getAuthUserName = () => `${getUser().fName} ${getUser().lName}`;

export const getUserId = () => getUser().userId;
export const getClientDB = () => {
	const user = getUser();
	const clientApp = initializeApp(
		user.User_And_Organization_Information.firestoreConfig,
		user.User_And_Organization_Information.organisationPathFirebase
	);
	const clientDB = getFirestore(clientApp);
	return clientDB;
};

export const getXProjKey = () => {
	return (
		getUser()?.User_And_Organization_Information.firestoreConfig['x-proj-key'] ??
		''
	);
};
export const clear = () => localStorage.clear();

export const create = () => {
	axios.defaults.headers.common = {
		'x-proj-key': getXProjKey(),
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_BASE_URL,
	});
};

export const axiosInstance = () => axios;
