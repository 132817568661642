import { clear } from 'api/AxiosManager';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { showLoading } from 'reducers/Alerts/AlertActions';
import { IdleTimerProvider } from 'react-idle-timer';

export interface IScroll {
	children: React.ReactNode;
}

const ScrollToTop = ({ children }: IScroll) => {
	const location = useLocation();
	const dispatch: any = useDispatch();
	const history = useHistory();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	const onIdle = () => {
		dispatch(showLoading());
		localStorage.removeItem('token');
		history.push('/');
		window.location.reload();
	};

	return (
		<>
			{children}
			{localStorage.getItem('token') &&
				localStorage.getItem('token').length !== 0 && (
					<IdleTimerProvider timeout={1000 * 30000} onIdle={onIdle} />
				)}
		</>
	);
};

export default ScrollToTop;
