import React from 'react';
import SideBar from '../Lane';
import Categories from './Categories';
import Header from '../../../../layouts/components/Header';

function Items() {
	const [showLane, setShowLane] = React.useState(false);
	return (
		<div className='flex'>
			<div className='h-full w-full relative'>
				<Header />
				<Categories />
			</div>
			<SideBar showLane={showLane} setShowLane={setShowLane} />
		</div>
	);
}

export default Items;
