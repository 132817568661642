import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'components/Spinner';
import queryString from 'query-string';
import LeftStickyBar from 'views/Home/LeftStickyBar';
import { RootStore } from '../reducers';
import { getUser } from 'api/AxiosManager';
import Header from './components/Header';

export function PrivateRoute({ component: Component, ...props }: any) {
	const [readyToRender, setReadyToRender] = useState(false);
	const { loading } = useSelector((state: RootStore) => state.alertsReducer);
	const [isUserPresent, setIsUserPresent] = useState(false);
	const { search } = useLocation();
	const { token }: any = queryString?.parse(search);
	useEffect(() => {
		try {
			if (token) {
				localStorage.setItem('token', token);
				const redirectionLink = getUser().Redirection_Link;
				localStorage.setItem('redirectionLink', redirectionLink);
				setIsUserPresent(true);
				setReadyToRender(true);
			} else if (
				localStorage.getItem('token') &&
				localStorage.getItem('token').length !== 0
			) {
				setIsUserPresent(true);
				setReadyToRender(true);
			} else {
				setIsUserPresent(false);
				setReadyToRender(true);
			}
		} catch (error) {
			setIsUserPresent(false);
			setReadyToRender(true);
		}
	}, [token]);

	return window.navigator.onLine ? (
		<>
			{loading && <Spinner />}
			{readyToRender && (
				<div>
					{isUserPresent ? (
						<main>
							<div className='flex h-screen w-full p-3 gap-3 bg-gray-200'>
								<LeftStickyBar />

								<div className='w-full relative overflow-y-scroll'>
									{window.location.pathname !== '/' && <Header />}
									<Component {...props} />
								</div>
							</div>
						</main>
					) : (
						<div className='flex items-center justify-center h-screen flex-col gap-10'>
							<h1 className='text-4xl font-semibold text-secondary-600'>
								Your Session has expired
							</h1>
							<h1
								className='text-2xl underline cursor-pointer'
								onClick={() => {
									window.location.href = localStorage.getc('redirectionLink');
								}}
							>
								{' '}
								Click here to login again
							</h1>
						</div>
					)}
				</div>
			)}
		</>
	) : (
		<div className='flex items-center justify-center h-screen flex-col gap-10'>
			<h1 className='text-4xl font-semibold '>No Internet Connection</h1>
		</div>
	);
}

export default PrivateRoute;
