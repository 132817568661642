import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import posReducer from './POS/index';

import alertsReducer from './Alerts/index';

import layoutReducer from './Layout';

const rootStore = combineReducers({
	alertsReducer,
	layoutReducer,
	posReducer,
});
const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composedEnhancers = compose(middlewareEnhancer);
const store = createStore(rootStore, {}, composedEnhancers);

export type RootStore = ReturnType<typeof rootStore>;

export default store;
