import React, { useEffect, useState } from 'react';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { useDispatch } from 'react-redux';
import MUIGridPagination from 'components/MUIGridPagination';
import { useHistory } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid-pro';
import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import { GetAllOrderWithPagination, GetCounts } from 'firebaseApis/orders';
import moment from 'moment';
import Toast, { ToastTypes } from 'components/ToastNotify';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';
import { GetOrganizationInfoById } from 'firebaseApis/locations';
import { IOrderData } from 'interfaces';
import Button from 'storybook-mui/build/components/Button';
import InputTextField from 'storybook-mui/build/components/TextField';
import Receipt from 'views/Home/Components/OrderReceipt/Receipt';

export function OrdersPagination() {
	const dispatch: any = useDispatch();
	const [documentsData, setDocumentsData] = useState<any>([]);
	const [pageSize, setPageSize] = useState<number>(10);
	const [pageNum, setPageNum] = useState<number>(0);
	const [totalRows, setTotalRows] = useState<number>(0);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [firstDocument, setFirstDocument] = useState<any>(null);
	const [lastDocument, setLastDocument] = useState<any>(null);
	const [countsObj, setCountsObj] = useState<any>(null);
	const [selectedOrder, setSelectedOrder] = useState<IOrderData>(null);
	const getCounts = async () => {
		const result = await GetCounts();
		setCountsObj(result);
	};
	const history = useHistory();
	const [orgData, setOrgData] = useState<any>(null);
	const [filters, setFilters] = useState<any>({
		id: '',
	});
	const getDocumentsData = async (pagesize: number) => {
		try {
			dispatch(showLoading());
			const result = await GetAllOrderWithPagination({
				firstDocument,
				lastDocument,
				rowsPerPage: pagesize,
				type: 'first',
				filters,
			});
			const count: any = await GetCounts();
			setTotalRows(count.Pos_Orders);
			setDocumentsData(result.data);
			setFirstDocument(result.firstDocument);
			setLastDocument(result.lastDocument);
			setTotalPages(Math.ceil(count.Pos_Orders / pagesize));

			const result2 = await GetOrganizationInfoById();
			setOrgData(result2);
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Unable to retrieve orders', type: ToastTypes.ERROR });
		}
	};

	const onRowsPerPageChange = (val: number) => {
		setPageSize(val);
		setTotalPages(0);
		setPageNum(0);
		getDocumentsData(val);
	};
	const onPageChange = async (value: string, page: number) => {
		try {
			dispatch(showLoading());
			setPageNum(page);
			let result: any;
			if (value === 'next') {
				result = await GetAllOrderWithPagination({
					firstDocument,
					lastDocument,
					rowsPerPage: pageSize,
					type: 'next',
					filters: null,
				});
			} else if (value === 'previous') {
				result = await GetAllOrderWithPagination({
					firstDocument,
					lastDocument,
					rowsPerPage: pageSize,
					type: 'prev',
					filters: null,
				});
			}
			setDocumentsData(result.data);
			setFirstDocument(result.firstDocument);
			setLastDocument(result.lastDocument);
			dispatch(hideLoading());
		} catch (error) {
			Toast({ title: error.message, type: ToastTypes.ERROR });
		}
	};
	const [showReceiptModal, setShowReceiptModal] = useState(false);
	useEffect(() => {
		getDocumentsData(pageSize);
		getCounts();
	}, []);
	const columns: GridColDef[] = [
		{
			headerName: 'Order ID',
			field: 'id',
			flex: 1,
		},
		{
			headerName: 'Customer Name',
			field: 'customer',
			flex: 1,
			renderCell: (data: any) => {
				let customerName = 'GUEST';
				if (data?.row?.customer_info?.id !== 'GUEST') {
					customerName =
						data.row.customer_info.First_Name +
						' ' +
						data.row.customer_info.Last_Name;
				}
				return <div>{customerName}</div>;
			},
		},
		{
			headerName: 'Order Date',
			field: 'updated_at',
			renderCell: (rowData: any) => {
				return (
					<span>{moment(rowData.row.updated_at).format('MM-DD-YYYY hh:mm A')}</span>
				);
			},

			flex: 1,
		},
		{
			headerName: 'Order Status',
			field: 'order_status',
			flex: 1,
		},
		{
			headerName: 'Refunds / Void',
			field: 'refunds',
			flex: 1,
			renderCell: (rowData: any) => {
				return <span>{rowData.row?.refund_status ?? 'N/A'}</span>;
			},
		},
		{
			headerName: 'Amount',
			field: 'authorized_amount',
			flex: 1,
			renderCell: (rowData: any) => {
				return (
					<span>
						${rowData?.row?.authorized_amount ?? rowData.row?.amount?.Total ?? 0}
					</span>
				);
			},
		},
		{
			headerName: 'Action',
			field: '',
			flex: 1,
			renderCell: (data: any) => {
				return (
					<div className='flex gap-5'>
						<h1
							className='underline cursor-pointer'
							onClick={() => {
								setSelectedOrder(data.row);
								setShowReceiptModal(true);
							}}
						>
							Print
						</h1>
						<Icon
							onClick={() => history.push(`/reports/order/${data.row.id}`)}
							icon={IconTypes.Eye}
							className='cursor-pointer'
						/>
					</div>
				);
			},
		},
	];

	const searchData = () => {
		getDocumentsData(0);
	};

	return (
		<div className='mt-5 h-[80vh]'>
			{/* hidden for now */}
			<div className='bg-white p-2 my-5 hidden'>
				<div className='my-5  gap-5 grid grid-cols-3'>
					<InputTextField
						name='order_id'
						fieldId='order_id'
						onChange={(e) => {
							setFilters({ ...filters, orderid: e.target.value });
						}}
						placeholder='Order ID'
						value={filters.orderid}
						labelName='Order ID'
					/>
					<div>
						<Button
							title='Filter'
							onClick={() => searchData()}
							variant='contained'
							color='primary'
						/>
					</div>
				</div>
			</div>
			{countsObj && (
				<>
					<MUIGridGloabl
						columns={columns}
						dataSource={documentsData}
						rowCount={totalRows}
						noRecordsText={`No Orders found`}
					/>
					<MUIGridPagination
						onPageChange={onPageChange}
						onRowsPerPageChange={onRowsPerPageChange}
						totalRows={totalRows}
						totalPages={totalPages}
						pageSize={pageSize}
						pageNum={pageNum}
					/>
				</>
			)}

			{selectedOrder && showReceiptModal && (
				<Modal
					size='sm'
					title=''
					content={
						<div className='flex flex-col  gap-5'>
							<div className='flex justify-end'>
								<Icon
									icon={IconTypes.Cancel}
									className='cursor-pointer'
									onClick={() => {
										setShowReceiptModal(false);
									}}
								/>
							</div>
							<Receipt
								orderId={selectedOrder.id}
								onClose={() => {
									setShowReceiptModal(false);
								}}
							/>
						</div>
					}
					modalId=''
					open={showReceiptModal}
					setOpen={setShowReceiptModal}
				/>
			)}
		</div>
	);
}

export default OrdersPagination;
