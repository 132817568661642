import { Divider } from '@mui/material';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { AddItemsToLane, AddTipInDevice } from 'firebaseApis/lineitems';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import {
	SetGratuityPercentage,
	SetOrderId,
	SetPosView,
	SetTotalAmountWithoutTaxAndGratuity,
	SetTotalAmountWithTaxAndGratuity,
	SetTotalAmountWithTaxWithoutGratuity,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import Modal from 'storybook-mui/build/components/Modal';

function Footer() {
	const {
		laneItems,
		customer,
		totalAmountWithoutTaxAndGratuity,
		totalAmountWithTaxWithoutGratuity,
		deviceStatus,
		currency,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const [showCustomerOrGuestModal, setShowCustomerOrGuestModal] =
		useState(false);
	const dispatch: any = useDispatch();

	useEffect(() => {
		const taxAmount = 0;
		const temp = laneItems.reduce(
			(acc: number, product: any) =>
				acc + product.product_price * product.quantity,
			0
		);
		dispatch(SetTotalAmountWithoutTaxAndGratuity(temp));
		dispatch(SetTotalAmountWithTaxWithoutGratuity(temp + taxAmount));
	}, [dispatch, laneItems]);

	// only when device is turned on, we can add gratuity
	const checkout = async () => {
		try {
			dispatch(showLoading());
			const response = await AddItemsToLane({
				laneItems,
				subTotal: totalAmountWithoutTaxAndGratuity,
				tax: 0,
				total: totalAmountWithoutTaxAndGratuity,
				customer,
			});
			if (response.status === 'success') {
				dispatch(SetOrderId(response.data.orderid));
				if (deviceStatus === 'CONNECTED') {
					const tipOutputTextsInDevice = [
						{
							Text: '5%',
						},
						{
							Text: '15%',
						},
						{
							Text: '25%',
						},
						{
							Text: '30%',
						},
						{
							Text: '40%',
						},
					];
					AddTipInDevice({
						orderId: response.data.orderid,
						outputText: tipOutputTextsInDevice,
					})
						.then((response) => {
							const selectedGratuityPercentage = Number(
								tipOutputTextsInDevice[response.data[0] - 1].Text.split('%')[0]
							);
							const gratuity =
								(selectedGratuityPercentage * totalAmountWithTaxWithoutGratuity) / 100;
							dispatch(SetGratuityPercentage(selectedGratuityPercentage));
							dispatch(
								SetTotalAmountWithTaxAndGratuity(
									totalAmountWithoutTaxAndGratuity + gratuity
								)
							);
							dispatch(SetPosView('PAYMENT'));
							dispatch(hideLoading());
						})
						.catch((error) => {
							Toast({ title: 'Gratuity selection failed', type: ToastTypes.ERROR });
							dispatch(hideLoading());
						});
				} else {
					dispatch(SetPosView('GRATUITY'));
					dispatch(hideLoading());
				}
			} else {
				Toast({ title: 'Add line item failed', type: ToastTypes.ERROR });
				dispatch(hideLoading());
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Adding line items failed', type: ToastTypes.ERROR });
		}
	};

	return (
		<div className='p-5'>
			<div className='flex flex-col gap-2 rounded-md bg-gray-100 w-full px-4 py-2'>
				<div className='flex justify-between w-full items-center'>
					<h1 className='text-secondary-500 text-lg capitalize'>Sub Total</h1>
					<div className='text-gray-600 text-lg text-right w-32'>
						{currency} {totalAmountWithoutTaxAndGratuity ?? 0}
					</div>
				</div>

				<div className='flex  justify-between w-full items-center'>
					<h1 className='text-secondary-500 text-lg capitalize'>Tax</h1>
					<div className='text-gray-600 text-lg text-right w-32'>{currency} 0</div>
				</div>
				<Divider />
				<div className='flex  justify-between w-full items-center'>
					<h1 className='text-secondary-500 text-xl font-semibold capitalize'>
						Total
					</h1>
				</div>
			</div>

			<div className='w-full flex flex-col gap-2 mt-4'>
				<div className='grid grid-cols-1 w-full items-center justify-between gap-5 '>
					<Button
						title='Checkout'
						variant='contained'
						size='large'
						disabled={laneItems.length === 0}
						onClick={() => {
							if (customer.id === 'GUEST') {
								setShowCustomerOrGuestModal(true);
							} else {
								checkout();
							}
						}}
					/>
				</div>
			</div>

			<Modal
				title=''
				content={
					<div className='flex flex-col gap-5'>
						<div className='flex justify-between'>
							<h1 className='text-xl'>Contine with guest</h1>
							<Icon
								icon={IconTypes.Cancel}
								className='cursor-pointer'
								onClick={() => setShowCustomerOrGuestModal(false)}
							/>
						</div>
						<div className='flex'>
							<h1 className='text-xl'>
								No customer is selected , Are you sure you want to continue this order
								with a guest ?
							</h1>
						</div>
						<div className='flex justify-end gap-5'>
							<Button
								title='Add Customer'
								color='inherit'
								variant='outlined'
								size='large'
								onClick={() => {
									setShowCustomerOrGuestModal(false);
									dispatch(SetPosView('ADD_CUSTOMER'));
								}}
							/>
							<Button
								title='Yes , Continue'
								color='primary'
								variant='contained'
								size='large'
								onClick={() => {
									setShowCustomerOrGuestModal(false);
									checkout();
								}}
							/>
						</div>
					</div>
				}
				modalId=''
				open={showCustomerOrGuestModal}
				setOpen={setShowCustomerOrGuestModal}
				size='sm'
			/>
		</div>
	);
}

export default Footer;
