import { Menu, Paper } from '@mui/material';
import { getUser } from 'api/AxiosManager';
import { GetLocationInfoById } from 'firebaseApis/locations';
import {
	GetPosPaymentTerminals,
	RefreshPosPaymentTerminals,
} from 'firebaseApis/terminals';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from 'reducers/Alerts/AlertActions';
import { SetDeviceStatus } from 'reducers/POS/PosActions';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';

function UserInfo() {
	const dispatch: any = useDispatch();
	const [deviceStatus, setDeviceStatus] = React.useState('DISCONNECTED');
	const [locationData, setLocationData] = React.useState(null);
	const { fName, lName } = getUser()?.User_And_Organization_Information;
	const [openUserInfo, setOpenUserInfo] = React.useState(false);
	useEffect(() => {
		dispatch(showLoading());
		GetLocationInfoById()
			.then((response) => {
				setLocationData(response);
				dispatch(hideLoading());
			})
			.catch(() => {
				dispatch(hideLoading());
			});
	}, []);

	const getPaymentTerminals = async () => {
		try {
			dispatch(showLoading());
			const result = await GetPosPaymentTerminals();
			const ptId =
				getUser()?.Work_Station_And_Terminal_Information
					?.Connected_Payment_Terminal;
			const pt = result?.data?.find((item) => item?.Payment_Terminal_Id === ptId);
			if (pt) {
				setDeviceStatus(pt?.POIState);
				dispatch(SetDeviceStatus(pt?.POIState));
			}
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
		}
	};

	const refreshDeviceStatus = async () => {
		try {
			dispatch(showLoading());
			await RefreshPosPaymentTerminals();
			getPaymentTerminals();
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
		}
	};

	useEffect(() => {
		getPaymentTerminals();
	}, []);

	return (
		<div className='flex justify-end items-center w-max relative h-max'>
			<Icon
				icon={IconTypes.AccountCircle}
				textSize='text-3xl'
				onClick={() => setOpenUserInfo(!openUserInfo)}
				className='text-gray-500 text-semibold cursor-pointer'
			/>
			{openUserInfo && (
				<Paper className='absolute top-10 p-5 z-10'>
					<div className='flex flex-col mr-1 w-max  z-10'>
						<div className='flex flex-col w-full justify-start'>
							<h1 className='text-2xl text-gray-darkest capitalize'>
								{fName} {lName}
							</h1>
							<h1 className=''>
								Date{' '}
								<span className='italic font-black'>
									{moment().format('MM-DD-YYYY')}
								</span>
							</h1>
							<h1 className=''>
								Work Station{' '}
								<span className='italic font-black'>
									{getUser().Work_Station_And_Terminal_Information.Work_Station_Name}
								</span>
							</h1>
							{locationData && (
								<h1 className=''>
									Location{' '}
									<span className='italic font-black'>{locationData.Location_Name}</span>
								</h1>
							)}
							<h1>Device Status : {deviceStatus.toUpperCase()}</h1>
							<hr />
							<div className='flex justify-between mt-3'>
								<h1
									className='underline cursor-pointer'
									onClick={() => {
										refreshDeviceStatus();
									}}
								>
									Refresh Device Status
								</h1>
								<Icon
									icon={IconTypes.Clear}
									onClick={() => {
										setOpenUserInfo(false);
									}}
									className='text-gray-700 text-semibold cursor-pointer'
								/>
							</div>
						</div>
					</div>
				</Paper>
			)}
		</div>
	);
}

export default UserInfo;
