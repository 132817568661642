import {
	cloudBaseUrl,
	create,
	getClientDB,
	getOrganisationIdFirebase,
	getUser,
} from 'api/AxiosManager';
import { where } from 'firebase/firestore';
import { collection, getDocs, query } from 'firebase/firestore/lite';
import { IProductInterface } from 'interfaces';

export const GetPosProducts = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/POS/Catalog_Category_Product`
			),
			where(
				'catalog',
				'==',
				getUser().Work_Station_And_Terminal_Information.Catalog
			)
		);
		const response = await getDocs(qry);
		const resultSnapshots = response.docs.map((d: any) => ({
			id: d.id,
			...d.data(),
		}));
		return resultSnapshots;
	} catch (error) {
		return error;
	}
};

export const AddItemsToLane = async ({
	laneItems,
	subTotal,
	total,
	customer,
}: any) => {
	const items = laneItems.map((item: IProductInterface) => {
		return {
			ItemID: 45,
			ProductCode: item.product_id,
			EanUpc: null,
			Quantity: item.quantity,
			UnitPrice: item.product_price,
			ItemAmount: (item.product_price * item.quantity).toFixed(2),
			ProductLabel: item.name,
			Description: item.name,
		};
	});

	try {
		const payload = {
			Organization_Id: getOrganisationIdFirebase(),
			Payment_Terminal_Id:
				getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal,
			customer_id: customer.id,
			customer_info: customer,
			location_id: getUser().Work_Station_And_Terminal_Information.Location_Id,
			work_station_id:
				getUser().Work_Station_And_Terminal_Information.Work_Station_Id,
			orderid: '',
			add_to_pos: getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal !== '' ? true : false,
			amount: {
				Subtotal: Number(subTotal).toFixed(2),
				Tax: 0,
				Total: Number(total).toFixed(2),
			},
			item: items,
		};

		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/add?Organization_Id=${getOrganisationIdFirebase()}`,
			payload
		);
		return result.data;
	} catch (error) {
		
	}
};

export const AddTipInDevice = async ({ orderId, outputText }: any) => {
	try {
		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/addtip?Organization_Id=${getOrganisationIdFirebase()}`,
			{
				Organization_Id: getOrganisationIdFirebase(),
				Payment_Terminal_Id:
					getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal,
				add_to_pos: true,
				OutputText: outputText,
				orderid: orderId,
			}
		);
		return result.data;
	} catch (error) {
		return error;
	}
};

export const RemoveLineItems = async ({
	customerId,
	orderId,
	items,
	subTotal,
	tax,
	total,
}) => {
	const itemsToRemove = items.map((item: IProductInterface) => {
		return {
			ItemID: 45,
			ProductCode: item.product_id,
			EanUpc: null,
			Quantity: item.quantity,
			UnitPrice: item.product_price,
			ItemAmount: (item.product_price * item.quantity).toFixed(2),
			ProductLabel: item.name,
			Description: item.name,
		};
	});
	const payload = {
		organizationid: getOrganisationIdFirebase(),
		customer_id: customerId,
		Payment_Terminal_Id:
			getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal,
		orderid: orderId,
		add_to_pos: true,
		amount: {
			Subtotal: subTotal,
			Tax: tax,
			Total: total,
		},
		item: itemsToRemove,
	};
	try {
		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/remove?Organization_Id=${getOrganisationIdFirebase()}`,
			payload
		);
		if (result.data.status === 'success') {
			return true;
		}
		return false;
	} catch (error) {}
};

export const RemoveAllLineItems = async ({ customerId, orderId }) => {
	const payload = {
		organizationid: getOrganisationIdFirebase(),
		customer_id: customerId,
		orderid: orderId,
		Payment_Terminal_Id:
			getUser().Work_Station_And_Terminal_Information.Connected_Payment_Terminal,
		add_to_pos: true,
		amount: {
			Subtotal: 0,
			Tax: 0,
			Total: 0,
		},
		item: [],
	};
	try {
		const instance = create();
		const result = await instance.post(
			`${cloudBaseUrl()}/verifone/item/remove?Organization_Id=${getOrganisationIdFirebase()}`,
			payload
		);
		if (result.data.status === 'success') {
			return true;
		}
		return false;
	} catch (error) {}
};
