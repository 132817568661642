import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { SearchContacts } from 'firebaseApis/contacts';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { SetCustomer, SetPosView } from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import InputTextField from 'storybook-mui/build/components/TextField';

function SearchExisting({ setModelView }: ISearchExisting) {
	const [contactsData, setContactsData] = useState<any>([]);
	const dispatch: any = useDispatch();
	const customerForm = useFormik({
		initialValues: {
			First_Name: '',
			Last_Name: '',
			Email_Id: '',
			Phone_Number: '',
		},
		onSubmit: async () => {
			dispatch(showLoading());
			const response = await SearchContacts(customerForm.values);
			const tempContactsData = response.data.map((item: any) => ({
				id: item.document_id,
				...item.document,
			}));
			if (tempContactsData.length === 0) {
				Toast({
					title:
						'No Contacts Matches Your Search Results , Please add a New Customer',
					type: ToastTypes.ERROR,
				});
				setContactsData([]);
			} else {
				setContactsData(tempContactsData);
			}

			dispatch(hideLoading());
		},
	});

	const columns = [
		{
			headerName: 'First Name',
			field: 'First_Name',
			flex: 1,
		},
		{
			headerName: 'Last Name',
			field: 'Last_Name',
			flex: 1,
		},
		{
			headerName: 'Email Id',
			field: 'Email_Id',
			flex: 1,
		},
		{
			headerName: 'Phone Number',
			field: 'Phone_Number',
			flex: 1,
		},
	];
	return (
		<div className='flex flex-col space-y-5'>
			<form
				className='flex flex-col space-y-5 items-start mt-5'
				onSubmit={customerForm.handleSubmit}
			>
				<InputTextField
					name='First_Name'
					onChange={customerForm.handleChange}
					value={customerForm.values.First_Name}
					labelName='First Name'
					fieldId=''
				/>
				<InputTextField
					name='Last_Name'
					onChange={customerForm.handleChange}
					value={customerForm.values.Last_Name}
					labelName='Last_Name'
					fieldId='Last_Name'
				/>
				<InputTextField
					name='Email_Id'
					onChange={customerForm.handleChange}
					value={customerForm.values.Email_Id}
					labelName='Email Id'
					fieldId='Email_Id'
				/>
				<InputTextField
					name='Phone_Number'
					onChange={customerForm.handleChange}
					value={customerForm.values.Phone_Number}
					labelName='Phone Number'
					fieldId='Phone_Number'
				/>
				{contactsData?.length > 0 && (
					<MUIGridGloabl
						columns={columns}
						dataSource={contactsData}
						rowCount={contactsData?.length}
						onRowClick={(value) => {
							dispatch(SetCustomer(value.row));
							dispatch(SetPosView('ITEMS'));
						}}
					/>
				)}
				<div className='flex justify-end w-full space-x-5'>
					<Button
						title='Cancel'
						variant='outlined'
						color='inherit'
						onClick={() => setModelView('SELECTION')}
					/>
					<Button title='search' type='submit' variant='contained' color='primary' />
				</div>
			</form>
		</div>
	);
}

export default SearchExisting;

export interface ISearchExisting {
	setModelView: any;
}
