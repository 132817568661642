import React, { useEffect } from 'react';
import {
	SetCustomer,
	SetPosView,
	SetSideBarStatus,
} from 'reducers/POS/PosActions';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import { IPos } from 'reducers/POS';
import IconButtonComponent from 'storybook-mui/build/components/IconButton';
import LaneItems from './LaneItems';
import Footer from './Footer';

function Lane({showLane , setShowLane} : ILaneProps) {
	const { orderId, laneItems, customer }: IPos = useSelector(
		(state: any) => state.posReducer
	);
	const [openCheckout, setOpenCheckout] = React.useState(true);
	const dispatch: any = useDispatch();

	useEffect(() => {
		if (openCheckout) {
			dispatch(SetSideBarStatus('opened'));
		} else {
			dispatch(SetSideBarStatus('closed'));
		}
	}, [dispatch, openCheckout]);

	return (
		<div className='relative'>
			<Drawer
				sx={{
					width: openCheckout ? '420px' : '0',
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: openCheckout ? '420px' : '0',
						boxSizing: 'border-box',
					},
				}}
				variant='persistent'
				anchor='right'
				open={openCheckout}
			>
				<div className='bg-white h-screen'>
					<div className='sticky'>
						{openCheckout && (
							<div className='absolute right-10'>
								<IconButtonComponent
									id='drawer-open'
									ariaLabel='drawer-open'
									content={
										<Icon
											icon={IconTypes.KeyboardDoubleArrowRight}
											className='text-gray-darkest '
										/>
									}
									edge='end'
									color='secondary'
									onClick={() => setOpenCheckout(false)}
								/>
							</div>
						)}
						<div className='min-h-screen flex flex-col gap-6 justify-between'>
							<div className='flex flex-col gap-4 pt-8'>
								<div className='grid grid-cols-1 lg:grid-cols-2 gap-6 w-full px-5 mt-6'>
									<div
										onClick={() => dispatch(SetPosView('ADD_CUSTOMER'))}
										className={`flex flex-col py-2 px-5 hover:shadow-lg col-span-2
										 ${customer.id === 'GUEST' ? 'bg-info-500' : 'bg-gray-300 pointer-events-none'}
										 rounded-lg items-center justify-center cursor-pointer w-full`}
									>
										<Icon
											icon={IconTypes.Add}
											className='text-white font-semibold text-4xl'
										/>
										<div className='flex flex-col justify-center items-center'>
											<h1 className='text-white text-sm leading-3'>Add Customer</h1>
											<h1 className='text-white text-xl font-semibold'>Information</h1>
										</div>
									</div>
								</div>

								{customer.id !== 'GUEST' && (
									<div className='flex justify-between border m-5 py-2'>
										<h1 className='text-gray-700 ml-5  text-lg'>
											<b>
												Customer : {customer?.First_Name} {customer?.Last_Name}
											</b>
										</h1>
										<Icon
											onClick={() =>
												dispatch(
													SetCustomer({
														id: 'GUEST',
													})
												)
											}
											icon={IconTypes.Delete}
											className='text-gray-700 mr-5 cursor-pointer'
										/>
									</div>
								)}

								{orderId !== '' && laneItems.length > 0 && (
									<h1 className='text-gray-700 ml-5  text-lg'>Order ID - {orderId}</h1>
								)}
								<LaneItems />
							</div>

							<Footer />
						</div>
					</div>
				</div>
			</Drawer>

			{!openCheckout && (
				<Icon
					icon={IconTypes.KeyboardDoubleArrowLeft}
					onClick={() => setOpenCheckout(true)}
					className='text-white text-xl font-extrabold  rounded-l-full cursor-pointer absolute right-0 top-16 z-50 p-2 bg-gray-700  hover:p-4 transition-all'
				/>
			)}
		</div>
	);
}

export default Lane;

export interface ILaneProps {
	showLane: boolean;
	setShowLane: (showLane: boolean) => void;
}
