import {cloudBaseUrl, create, getOrganisationIdFirebase} from 'api/AxiosManager'


export const GetPosPaymentTerminals = async () => {
	try {
		const instance = create();
		const result = await instance.get(
			`${cloudBaseUrl()}/verifone/payment_terminals/list?Organization_Id=${getOrganisationIdFirebase()}`
		);
		return result.data;
	} catch (error) {
		return [];
	}
};

export const RefreshPosPaymentTerminals = async () => {
	try {
		const instance = create();
		const result = await instance.get(
			`${cloudBaseUrl()}/verifone/payment_terminals/refresh?Organization_Id=${getOrganisationIdFirebase()}`
		);
		return result.data;
	} catch (error) {
		return error;
	}
};