import {
	getClientDB,
	getOrganisationIdFirebase,
	create,
	siteConfigConstants,
} from 'api/AxiosManager';
import { getDocs, query } from 'firebase/firestore';
import { collection, addDoc } from 'firebase/firestore/lite';
import moment from 'moment';

export const GetAllCountries = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/Lookups/Countries`)
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const SearchContacts = async (values: any) => {
	try {
		let queries = [];
		Object.keys(values).forEach((key, index) => {
			if (key === 'Email_Id' || key === 'Phone_Number') {
				values[key] !== '' &&
					queries.push({
						groupedFieldName: key === 'Email_Id' ? 'Emails' : 'Phones',
						fieldName: '',
						groupedFieldKey: key,
						Operator: 'CONTAINS',
						logical_seperator_with_prev: index === 0 ? 'AND' : 'OR',
						fieldValue: values[key],
						data_type: 'STRING',
					});
			} else {
				values[key] !== '' &&
					queries.push({
						groupedFieldName: '',
						fieldName: key,
						groupedFieldKey: '',
						Operator: 'CONTAINS',
						logical_seperator_with_prev: index === 0 ? 'AND' : 'OR',
						fieldValue: values[key],
						data_type: 'STRING',
					});
			}
		});
		const payload = {
			organization_id: getOrganisationIdFirebase(),
			query: queries,
		};
		const instance = create();
		const response = await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/importexportcontacts/simpleContactSearch?called_from=UI`,
			payload
		);
		return {
			data: response.data,
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const SaveContact = async (payload: any) => {
	try {
		const formatedPayload = {
			First_Name: payload.First_Name,
			Last_Name: payload.Last_Name,
			ContactInfo: [
				{
					Email_Id: payload.Email_Id,
					Country_Code: payload.Country_Code,
					Phone_Number: payload.Phone_Number,
				},
			],
			GroupedFieldsValues: {
				Email: payload.Email_Id,
				Phone_Number: payload.Phone_Number,
				Country_Code: payload.Country_Code,
			},
			Verison: "1.0.0",
		};
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Contacts/Contacts`
			),
			{
				...formatedPayload,
				Created_At: moment().toString(),
			}
		);
		const instance = create();
		await instance.post(
			`${
				siteConfigConstants().REACT_APP_BASE_URL
			}/importexportcontacts/insertContact?organization_id=${getOrganisationIdFirebase()}&docid=${
				result.id
			}`
		);
		return {
			data: { ...formatedPayload, id: result.id },
		};
	} catch (error) {
		return error;
	}
};

export const GetPosContacts = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/Contacts/Contacts`)
		);

		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs
			.filter((dc) => dc?.data().Display !== false)
			.map((document: any) => ({
				id: document.id,
				...document.data(),
				...document.data().GroupedFieldsValues,
			}));
        
		return resultArray;
	} catch (error) {
		
		return error;
	}
};
