import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import {
	collection,
	doc,
	endBefore,
	getDoc,
	getDocs,
	limit,
	limitToLast,
	orderBy,
	query,
	setDoc,
	startAfter,
	where,
} from 'firebase/firestore';
import moment from 'moment';

const checkInternetConnection = async () => {
	if (navigator.onLine) {
		return true;
	}
	throw new Error('No internet connection');
};

export const GetAllOrders = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`),
			orderBy('updated_at', 'desc')
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray = resultSnapshots.docs.map((document) => ({
			...document.data(),
			id: document.id,
			updated_at: moment(document.data().updated_at).format('DD-MM-YYYY'),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetOrderById = async (orderId: string) => {
	try {
		const response: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`, orderId)
		);
		return { ...response.data(), id: response.id };
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const GetAllOrderWithPagination = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	filters,
}) => {
	try {
		if (rowsPerPage === 0) {
			rowsPerPage = 10;
		}
		let querySnapshots: any;

		if (type === 'next') {
			const qry = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),

				orderBy('created_at', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);

			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),

				orderBy('created_at', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry: any = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/POS/orders`),

				orderBy('created_at', 'desc'),
				limit(rowsPerPage)
			);

			querySnapshots = await getDocs(qry);
		}
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		console.log(error);
		return {
			data: [],
		};
	}
};

export const GetCounts = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts')
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const CancelOrder = async (orderId: string) => {
	try {
		await checkInternetConnection();
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`, orderId)
		);
		const orderData = docResult.data();
		const updatedData = {
			...orderData,
			order_status: 'Cancelled',
			updated_at: moment().toString(),
		};
		await setDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/POS/orders`, orderId),
			updatedData
		);
		return {
			success: true,
		};
	} catch (error) {
		return {
			success: false,
			message: error.message,
		};
	}
};
