import { IOrderData } from 'interfaces';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddProductToLane,
	SetCustomer,
	SetOrderId,
	SetPosView,
} from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import { useHistory } from 'react-router-dom';

function Basic({ orderData, workStationData }: IBasic) {
	const { currency } = useSelector((state: any) => state.posReducer);
	const dispatch: any = useDispatch();
	const history = useHistory();
	const continueOrder = () => {
		orderData.items.forEach((item) => {
			dispatch(
				AddProductToLane({
					name: item.ProductLabel,
					product_price: item.UnitPrice,
					quantity: item.Quantity,
					product_id: item.ProductCode,
				})
			);
		});
		dispatch(SetOrderId(orderData.id));
		if (orderData.customer_info.id !== 'GUEST') {
			dispatch(SetCustomer(orderData.customer_info));
		}
		history.push('/');
		dispatch(SetPosView('GRATUITY'));
	};
	return (
		<div className='min-h-screen'>
			<div className='flex justify-between  bg-white p-5 shadow-md mt-5'>
				<div className='flex flex-col gap-5'>
					<h1>
						<b>Order Id</b> : {orderData?.id}
					</h1>
					{orderData?.customer_info.id !== 'GUEST' ? (
						<div>
							<h1>
								<b>Customer Name</b> : {orderData?.customer_info?.First_Name}
								{orderData?.customer_info?.Last_Name}
							</h1>
							<h1>
								<b>Phone Number</b> :{' '}
								{orderData?.customer_info?.ContactInfo[0].Phone_Number}
							</h1>
							<h1>
								<b>Email</b> : {orderData?.customer_info?.ContactInfo[0].Email_Id}
							</h1>
						</div>
					) : (
						<div>
							<h1>
								<b>Customer</b> : Guest
							</h1>
						</div>
					)}

					<h1>
						<b>Date</b> :{' '}
						{moment(orderData?.updated_at).format('MM-DD-YYYY hh:mm:ss A')}
					</h1>
					<h1>
						<b>Work Station</b> : {workStationData?.Work_Station_Name}
					</h1>

					<h1>
						<b>Payment Status : </b>
						{orderData.payment_status}
					</h1>
					<h1>
						<b>Refund Status : </b>
						{orderData.refund_status ?? 'N/A'}
					</h1>
					<h1>
						<b>Order Status : </b>
						{orderData.order_status}
					</h1>
					{orderData.order_status !== 'Completed' && (
						<div className='w-max'>
							<Button
								title='Contine order'
								onClick={() => continueOrder()}
								variant='contained'
								color='primary'
							/>
						</div>
					)}
				</div>
				<div className='flex flex-col gap-5'>
					<h1 className='text-xl'>
						SubTotal : {currency} {orderData?.amount?.Subtotal}
					</h1>
					<h1 className='text-xl'>
						Gratuity : {currency} {orderData.amount.Tip ?? 0}{' '}
					</h1>
					<h1 className='text-xl'>Tax : {currency} 0</h1>
					<hr />
					<h1 className='text-5xl font-semibold text-secondary-500 border-b-2 border-b-secondary-500'>
						Total : {currency} {orderData?.amount?.Total}
					</h1>
				</div>

				<div></div>
			</div>
		</div>
	);
}

export default Basic;

export interface IBasic {
	orderData: IOrderData;
	workStationData: any;
}
