import Toast, { ToastTypes } from 'components/ToastNotify';
import { MakePayment } from 'firebaseApis/payments';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { IPos } from 'reducers/POS';
import AutocompleteField from 'storybook-mui/build/components/Autocomplete';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import InputTextField from 'storybook-mui/build/components/TextField';
import { creditCardSchema } from './validations';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import NumberField from 'storybook-mui/build/components/NumberField';
import SelectField from 'storybook-mui/build/components/Select';

function CreditCardModal({
	showCreditCardModal,
	setShowCreditCardModal,
	setCreditCardTransactionSuccess,
	amount,
}: CreditCardModalProps) {
	const dispatch: any = useDispatch();
	const {
		currency,
		orderId,
		customer,
		totalAmountWithTaxAndGratuity,
		gratuityAmount,
	}: IPos = useSelector((store: RootStore) => store.posReducer);
	const [validateOnChange, setValidateOnChange] = React.useState(false);
	const cardForm = useFormik({
		initialValues: {
			cardNumber: '',
			expiryYear: '',
			expiryMonth: '',
			cvv: '',
			zip: '',
			country: 'US',
			firstName: '',
			lastName: '',
		},
		validationSchema: creditCardSchema,
		validateOnChange: validateOnChange,
		onSubmit: async (values) => {
			try {
				dispatch(showLoading());
				const response = await MakePayment({
					values,
					orderId,
					orderAmount: totalAmountWithTaxAndGratuity,
					customerId: customer?.id || '',
					paymentModes: {
						virtualCards: amount,
						physicalCards: 0,
						cash: 0,
						coupons: 0,
					},
					transactionType: 'virtualCard',
					tip: gratuityAmount.toFixed(2),
				});
				dispatch(hideLoading());
				if (response.data.payment_status === 'success') {
					Toast({ title: 'Payment Success', type: ToastTypes.SUCCESS });
					setCreditCardTransactionSuccess();
				} else {
					Toast({
						title: response?.data?.errorText ?? 'Payment Failed',
						type: ToastTypes.ERROR,
					});
				}
			} catch (error) {
				Toast({ title: 'Payment Failed', type: ToastTypes.ERROR });
				dispatch(hideLoading());
			}
		},
	});

	useEffect(() => {
		if (customer.id !== 'GUEST') {
			cardForm.setValues({
				...cardForm.values,
				firstName: customer.First_Name,
				lastName: customer.Last_Name,
			});
		}
	}, []);

	const monthOptions = [];
	const num = 12;
	Array.from(Array(num).keys()).map((i) => {
		if (i < 9) {
			monthOptions.push({
				value: `0${i + 1}`,
				label: `0${i + 1}`,
			});
		} else {
			monthOptions.push({
				value: `${i + 1}`,
				label: `${i + 1}`,
			});
		}
	});

	const yearOptions = [];
	const yearStart = new Date().getFullYear();
	const yearEnd = yearStart + 40;
	const num2 = yearEnd - yearStart;
	Array.from(Array(num2).keys()).map((i) => {
		yearOptions.push({
			value: `${yearStart + i}`,
			label: `${yearStart + i}`,
		});
	});

	return (
		<Modal
			title='Enter Credit Card Details'
			content={
				<div>
					<div className='flex gap-5 items-start justify-center'>
						<div className='flex flex-col gap-5'>
							<h1 className='text-info-500 text-3xl font-semibold'>
								Amount : {currency} {amount}
							</h1>
							<Cards
								cvc={cardForm.values.cvv}
								expiry={
									cardForm.values.expiryMonth +
									'/' +
									cardForm.values.expiryYear.substring(2, 4)
								}
								name={cardForm.values.firstName + ' ' + cardForm.values.lastName}
								number={cardForm.values.cardNumber}
								focused='number'
							/>
						</div>
						<form onSubmit={cardForm.handleSubmit}>
							<div className='flex flex-col gap-5 mt-5'>
								<div>
									<NumberField
										labelName='Card Number'
										name='cardNumber'
										fieldId='cardNumber'
										onChange={cardForm.handleChange}
										value={cardForm.values.cardNumber}
										placeholder='Card Number'
										valid={cardForm.errors.cardNumber === undefined}
										errorMessage={cardForm.errors.cardNumber}
										max={999999999999999999}
									/>
								</div>
								<div className='flex w-full gap-5'>
									<SelectField
										name='expiryMonth'
										fieldId='expiryMonth'
										onChange={cardForm.handleChange}
										value={cardForm.values.expiryMonth}
										placeholder='Exp Month (MM)'
										options={monthOptions}
										valid={cardForm.errors.expiryMonth === undefined}
										valueField='value'
										errorMessage={cardForm.errors.expiryMonth}
										displayField='label'
									/>
									<SelectField
										name='expiryYear'
										fieldId='expiryYear'
										onChange={cardForm.handleChange}
										value={cardForm.values.expiryYear}
										options={yearOptions}
										placeholder='Expiry Year (YYYY)'
										valueField='value'
										displayField='label'
										valid={cardForm.errors.expiryYear === undefined}
										errorMessage={cardForm.errors.expiryYear}
									/>
									<InputTextField
										labelName='CVV'
										name='cvv'
										fieldId='cvv'
										onChange={cardForm.handleChange}
										value={cardForm.values.cvv}
										placeholder='CVV'
										valid={cardForm.errors.cvv === undefined}
										errorMessage={cardForm.errors.cvv}
									/>
								</div>
								<div className='flex w-full gap-5'>
									<div className='w-full'>
										<InputTextField
											labelName='First Name'
											name='firstName'
											fieldId='firstName'
											onChange={cardForm.handleChange}
											value={cardForm.values.firstName}
											placeholder='First Name'
											valid={cardForm.errors.firstName}
											errorMessage={cardForm.errors.firstName}
										/>
									</div>
									<div className='w-full'>
										<InputTextField
											labelName='Last Name'
											name='lastName'
											fieldId='lastName'
											onChange={cardForm.handleChange}
											value={cardForm.values.lastName}
											placeholder=''
											valid={cardForm.errors.lastName === undefined}
											errorMessage={cardForm.errors.lastName}
										/>
									</div>
								</div>
								<div className='flex w-full gap-5'>
									<div className='w-full'>
										<InputTextField
											labelName='Zip Code'
											name='zip'
											fieldId='expiryDate'
											onChange={cardForm.handleChange}
											value={cardForm.values.zip}
											placeholder='Card Number'
											valid={cardForm.errors.zip === undefined}
											errorMessage={cardForm.errors.zip}
										/>
									</div>
									<div className='w-full'>
										<AutocompleteField
											fieldId='cvv'
											onChange={cardForm.handleChange}
											value={cardForm.values.country}
											placeholder='Country'
											valid={cardForm.errors.country === undefined}
											errorMessage={cardForm.errors.country}
											options={[]}
										/>
									</div>
								</div>

								<div className='flex justify-end gap-5'>
									<Button
										variant='outlined'
										color='inherit'
										onClick={() => setShowCreditCardModal(false)}
										title='Cancel'
									/>
									<Button
										title='PAY'
										type='submit'
										onClick={() => setValidateOnChange(true)}
										disabled={Object.keys(cardForm.errors).length > 0}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			}
			modalId='creditCardModal'
			open={showCreditCardModal}
			setOpen={setShowCreditCardModal}
			size='md'
		/>
	);
}

export default CreditCardModal;

export interface CreditCardModalProps {
	showCreditCardModal: boolean;
	setShowCreditCardModal: (showCreditCardModal: boolean) => void;
	amount: number;
	setCreditCardTransactionSuccess: any;
}
